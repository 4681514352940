import React from 'react'
import axios from 'axios';
import { Ring } from 'react-awesome-spinners'
import {useNavigate, Link} from 'react-router-dom'
import { useState, useEffect } from 'react';
import { RiLoginCircleFill } from "react-icons/ri";
import { BiUser } from "react-icons/bi";
import { AiOutlineMenu } from "react-icons/ai";
import Viewport from './components/Viewport';
import Mobilenav from './components/Mobilenav';
import logoMain from '../images/iconword.svg'
import {useGlobalContext} from '../assets/context';





const Landing = () => {

    const [isLoading, updateisLoading] = useState(true)
  
    
    const { width } = Viewport();
    const[inactiveMobileNav, activeMobileNav] = useState(false);
    const breakpoint = 578;


    const [allimages, allimagesUpdate] = useState([]);


    useEffect(()=>{

        const getALLimages = () =>{
            axios.get('/api/images')
            .then((response) => {
                allimagesUpdate(response.data.images);
                sessionStorage.setItem('allImagess', JSON.stringify(response.data.images));
                updateisLoading(false); // Data loading complete, set isLoading to false
            })
            .catch((error) => {
                console.log(error);
            });
        }

        const cachedallImagess = sessionStorage.getItem('allImagess');

        if(cachedallImagess){
            allimagesUpdate(JSON.parse(cachedallImagess));
            updateisLoading(false); // Data loading complete, set isLoading to false
        }else{
            getALLimages() 
        }



    }, [allimages])

    






    if(isLoading){
        return (
            <div className="relative overflow-hidden flex flex-col justify-center items-center bg-mainBackColor text-white w-full min-h-screen ">
                <Ring color = '#d4b657'/>
            </div>
        )
    }else{
        return (
            //whole container
            <div className="relative overflow-hidden flex flex-col justify-start items-center bg-mainBackColor text-white w-full min-h-screen ">
                
                {/* mobile menu */}
                {inactiveMobileNav && <Mobilenav closeMobileMenu = {() => activeMobileNav(false)}/>}

                {/* all sections */}
                {!inactiveMobileNav && <div className='absolute w-full h-full overflow-y-auto'>
                    {/* showcase and nav */}
                    <div className='relative w-full min-h-[39rem] flex flex-col justify-center items-start px-[1rem] md:px-[3rem] showcaseBagImg'>
                        <nav className="relative flex justify-between items-center min-h-[6rem] py-[1rem] w-full">
                            
                            <Link to = '/landing' className="relative flex justify-start items-center h-full w-[100px]">
                                <img src= {logoMain} alt="" className='h-[50%] ' />
                            </Link>

                            {width < breakpoint ? <button className="flex"  onClick={() => activeMobileNav(true)}>
                                <AiOutlineMenu className='text-white font-[500] text-[24px]'/>
                            </button> : ''}

                            <div className="hidden justify-center items-center sm:flex">
                                <ul className="flex items-center justify-center space-x-8">
                                    <li className="flex justify-center items-center text-white font-[500] text-[13.5px] tracking-wide transition-all uppercase hover:text-mainGoldColor"> <a href="/aboutus">About Us</a> </li>
                                    <li className="flex justify-center items-center text-white font-[500] text-[13.5px] tracking-wide transition-all uppercase hover:text-mainGoldColor"> <a href="/howitworks">How it works</a> </li>
                                    <li className="flex justify-center items-center text-white font-[500] text-[13.5px] tracking-wide transition-all uppercase hover:text-mainGoldColor"><a href="/locations" >Locations</a></li>
                                </ul>
                            </div>

                            <Link  to = "/signin" className="relative w-[100px] h-[33px] hidden justify-center items-center rounded space-x-1 sm:flex">
                                <p className="text-[15.5px] font-[500] tracking-wider text-white">Login </p>
                                <RiLoginCircleFill className='text-white text-[17.5px] font-[500] hover:text-mainGoldColor '/>
                            </Link> 

                        </nav>
                    

                        {/* showcase */}
                        <div className='relative w-full min-h-[35rem] flex justify-center items-center '>
                                <div className='relative w-full h-full flex flex-col justify-center items-center space-y-2 md:max-w-[70%]'>
                                    <h4 className='text-[15px] text-center text-mainGoldColor font-[500]'>Elevating your experience</h4>
                                    <h1 className='text-[30px] text-center text-white font-[500] uppercase'>Booking an escort, made easy.</h1>
                                    <p className='text-[17px] text-center text-white font-[350] pb-[1rem]'>We are the home of sexy, beautiful and authentic international escorts. Whether you're seeking a romantic evening, breathtaking pleasure or to fulfil your wildest fantasies, our adult directory will guide you through the hottest independent escorts and babes, with a secure, discreet and confidential platform. This is the escort experience, redefined.</p>

                                    <Link  to = "/signup" className="relative min-w-[130px] min-h-[39px] flex justify-center items-center border bg-mainGoldColor border-mainGoldColor rounded space-x-1 p-[0.50rem]">
                                        <p className="text-[14.5px] font-[500] tracking-wider text-white">Book an escort </p>
                                        <BiUser className='text-white text-[17.5px] font-[500]  '/>
                                    </Link> 
                                </div>
                        </div>
                    </div>


                    {/* featured escorts section */}
                    <div className='relative w-full min-h-[35rem] flex flex-col justify-center items-center space-y-14 px-[1rem] py-[3rem]'>
                        <div className='relative w-full flex flex-col justify-center items-center space-y-[0.40rem]'>
                            <h3 className='text-[25px] text-center text-white font-[500] capitalize'>Featured The Dirty Angels Escorts</h3>
                            <p className='text-[16px] text-center text-mainGoldColor font-[350]'>Take a look at some of our verified featured escorts.</p>
                        </div>

                        <div className='relative w-full min-h-[20rem] grid-container'>

                            {allimages.map((image, index) => (
                                <Link to = "/signup" className='relative flex flex-col justify-start items-start space-y-[0.10rem] rounded-md p-4  hover:bg-[#0b0b0b] hover:border-[#0b0b0b] hover:transition-all'>
                                    <img src={image.url} alt={`${image.ModelName}`} className='picture-size border border-black rounded-sm'/>
                                    <h6 className='text-[14.50px] text-white font-[500] pt-[0.50rem]'>{image.ModelName}</h6>
                                    <h6 className='text-[12.50px] text-[#bbbbbb]  font-[550]'>{image.Location}</h6>
                                    <h6 className='text-[14px] text-mainGoldColor font-[550]'>{image.AverageFee}</h6>
                                </Link>
                            ))}

                        </div>

                        <Link  to = "/signup" className="relative min-w-[130px] min-h-[39px] flex justify-center items-center border bg-inherit border-mainGoldColor rounded space-x-1 p-[0.50rem] ">
                            <p className="text-[14.5px] font-[500] tracking-wider text-[#9C9C9C] hover:text-mainGoldColor hover:transition-all">VIEW MORE </p>
                        </Link> 
                    </div>

                    {/* booking an escort made simple 2 */}
                    <div className='relative w-full min-h-[15rem] flex flex-col justify-center items-center space-y-12 px-[1rem] py-[3rem]'>
                        <h3 className='text-[22px] text-center text-mainGoldColor font-[500] capitalize md:max-w-[1000px] '>Booking an Escort, Made Easy.</h3>
                        <p className='text-[16px] text-justify text-white font-[450] md:max-w-[1000px]'>Need help booking an escort? you can email or Whatsapp message most agencies, and we are no different. Our support are the best in the business at helping you meet the best girl for your needs. If you need advice or recommendations, we know the girls better than anyone and can help you make the perfect choice.</p>
                        <p className='text-[16px] text-justify text-white font-[450] md:max-w-[1000px]'>We are open 24/7 to answer your booking requests, so if you are contemplating taking your dream call girl on a date, we are here for you, all hours of the day. Bookings can be made at short notice or in advance, the option is yours.</p>
                    </div>

                    {/* FAQ*/}
                    <div className='relative w-full min-h-[15rem] flex flex-col justify-center items-center space-y-10 px-[1rem] py-[3rem]'>
                        <h2 className='text-[22px] text-center text-mainGoldColor font-[500] uppercase md:max-w-[1000px]'>FAQ</h2>

                        <div className='relative w-full flex flex-col justify-center items-center space-y-8'>
                            <h4 className='text-[19px] text-center text-white font-[500] capitalize md:max-w-[1000px]'>What is an escort?</h4>
                            <p className='text-[16px] text-justify text-white font-[450] md:max-w-[1000px]'>A female or male escort will provide company or entertainment to a client. An escort is responsible for setting their own boundaries in regard to what they will (and won't) perform for clients. Many people believe that escorts only offer sexual services, however, this is not the reality. Some escorts choose to accompany clients to dinners, events and gatherings — while others will elect to offer sexual services. It's entirely at the escort's discretion. </p>
                        </div>

                        <div className='relative w-full flex flex-col justify-center items-center space-y-8'>
                            <h4 className='text-[19px] text-center text-white font-[500] capitalize md:max-w-[1000px]'>What services do The Dirty Angels offer?</h4>
                            <p className='text-[16px] text-justify text-white font-[450] md:max-w-[1000px]'>We have a diverse range of escorts offering a variety of services. There's something for every taste, ranging from erotic massage, BDSM, trans female, male escorts and more. </p>
                        </div>

                        <div className='relative w-full flex flex-col justify-center items-center space-y-8'>
                            <h4 className='text-[19px] text-center text-white font-[500] capitalize md:max-w-[1000px]'>Who are the escorts?</h4>
                            <p className='text-[16px] text-justify text-white font-[450] md:max-w-[1000px]'>All of the advertisements have been posted by independent private escorts from all over. The escort is responsible for the content on their profile and the services they offer.</p>
                        </div>

                        <div className='relative w-full flex flex-col justify-center items-center space-y-8'>
                            <h4 className='text-[19px] text-center text-white font-[500] capitalize md:max-w-[1000px]'>Do you have escorts near me?</h4>
                            <p className='text-[16px] text-justify text-white font-[450] md:max-w-[1000px]'> Yes — we showcase escorts from all around the world. We're bound to have an escort near you.  </p>
                        </div>

                        <div className='relative w-full flex flex-col justify-center items-center space-y-8'>
                            <h4 className='text-[19px] text-center text-white font-[500] capitalize md:max-w-[1000px]'>How do you book an escort?</h4>
                            <p className='text-[16px] text-justify text-white font-[450] md:max-w-[1000px]'> Our escort directory is secure, discreet and easy to book. Start by browsing through our thousands of sexy, beautiful escorts. When you feel you connect with an escort, you can select the escort and make a booking by following the on screen instructions.  </p>
                        </div>
                    </div>

                    <footer className='relative w-full flex flex-col justify-center items-center space-y-10 px-[1rem] py-[3rem] border-t-mainGoldColor border-t-[2px]'>
                        <div className='relative w-full flex flex-col-reverse justify-center items-center space-y-[3rem] space-y-reverse sm:flex-row sm:justify-between sm:items-start sm:space-y-[0rem] sm:space-x-[4rem] sm:max-w-[400px] md:max-w-[700px]'>
                                <ul className='relative flex flex-col justify-center items-center space-y-8'>
                                <Link to='/' className="relative flex justify-start items-center h-full w-[100px]">
                                    <img src= {logoMain} alt="" className='h-[50%] ' />
                                </Link>
                                    <li className='text-[#9C9C9C] text-[14px]'>© The Dirty Angels 2023</li>
                                </ul>

                        
                                <ul className='relative flex flex-col justify-center items-center space-y-8'>
                                    <li className='text-[15px] text-[#9C9C9C]'><Link to="/aboutus">About Us</Link></li>
                                    <li className='text-[15px] text-[#9C9C9C]'><Link to="/contactus">Contact Us</Link></li>
                                    <li className='text-[15px] text-[#9C9C9C]'><Link to="/signup">Book An Escort</Link></li>
                                </ul>
                        </div>
                    </footer>
                
                </div>}

            </div>
        )
    }
}

export default Landing