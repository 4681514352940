import React from 'react';
import { Route, Routes} from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';
import Landing from './pages/Landing';
import Signin from './pages/Signin';
import Signup from './pages/Signup';
import HowitWorks from './pages/HowitWorks';
import Aboutus from './pages/Aboutus';
import Locations from './pages/Locations';
import Contactus from './pages/Contactus';
import ForgotPassword from './pages/ForgotPassword';
import ResetPassword from './pages/ResetPassword';


//user routes
import PrivateRoute from './pages/PrivateRoutes/PrivateRoute ';

import UserDashboard from './pages/user/UserDashboard';
import UserContactUs from './pages/user/UserContactUs';
import UserBookModel from './pages/user/UserBookModel';
import UserBookedModels from './pages/user/UserBookedModels';
import UserDeposit from './pages/user/UserDeposit';
import UserNotifications from './pages/user/UserNotifications';
import Modelinfo from './pages/user/Modelinfo';


//admin routes
import AdminRoutes from './pages/PrivateRoutes/AdminRoutes';

import AdminDashboard from './pages/admin/AdminDashboard';
import ModelUpdate from './pages/admin/ModelUpdate';
import ModelDelete from './pages/admin/ModelDelete';
import AdminDeposits from './pages/admin/AdminDeposits';
import AdminManUsers from './pages/admin/AdminManUsers';
import AdminNotifications from './pages/admin/AdminNotifications';
import ModelBookings from './pages/admin/ModelBookings';





function App() {

  return (
    <>
      <Routes>
        <Route path='/' element={<Landing />}/>
        <Route path='/Signin' element={<Signin />}/>
        <Route path='/Signup' element={<Signup />}/>
        <Route path='/forgotpassword' element={<ForgotPassword />}/>
        <Route path='/resetpassword' element={<ResetPassword />}/>
        <Route path='/howitworks' element={<HowitWorks />}/>
        <Route path='/aboutus' element={<Aboutus />}/>
        <Route path='/Locations' element={<Locations />}/>
        <Route path='/Contactus' element={<Contactus />}/>
        <Route path='/Modelinfo' element={<Modelinfo />}/>

        {/* user routes */}
        <Route element={<PrivateRoute />}>
            <Route path='/user/dashboard' element = {<UserDashboard />} />
            <Route path='/user/bookmodel' element = {<UserBookModel />} />
            <Route path='/user/notifications' element = {<UserNotifications />} />
            <Route path='/user/bookedmodels' element = {<UserBookedModels />} />
            <Route path='/user/Modelinfo' element={<Modelinfo />}/>
            <Route path='/user/deposit' element = {<UserDeposit />} />
            <Route path='/user/Contactus' element = {<UserContactUs />} />
        </Route>

        {/* admin routes */}
        <Route element={<AdminRoutes />}>
            <Route path='/admin/dashboard' element = {<AdminDashboard />} />
            <Route path='/admin/modelupdate' element = {<ModelUpdate />} />
            <Route path='/admin/modeldelete' element = {<ModelDelete />} />
            <Route path='/admin/notifications' element = {<AdminNotifications />} />
            <Route path='/admin/managedeposits' element = {<AdminDeposits />} />
            <Route path='/admin/modelbookings' element = {<ModelBookings />} />
            <Route path='/admin/manageusers' element = {<AdminManUsers />} />
            <Route path='/Signin' element = {<Signin />} />
        </Route>

      </Routes>
    </>
  );
}

export default App;
