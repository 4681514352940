import React from 'react'
import { useState, useEffect } from 'react';
import {useNavigate, Link} from 'react-router-dom'
import Viewport from './components/Viewport';
import Mobilenav from './components/Mobilenav';
import logoMain from '../images/iconword.svg'
import { RiLoginCircleFill } from "react-icons/ri";
import { AiOutlineMenu } from "react-icons/ai";

const Aboutus = () => {

    const { width } = Viewport();

    const[inactiveMobileNav, activeMobileNav] = useState(false);


    const breakpoint = 578;

  return (
    //whole container
    <div className="relative overflow-hidden flex flex-col justify-start items-center bg-mainBackColor text-white w-full min-h-screen ">
        {/* mobile menu */}
        {inactiveMobileNav && <Mobilenav closeMobileMenu = {() => activeMobileNav(false)}/>}

        {/* all sections */}
        {!inactiveMobileNav && <div className='absolute w-full h-full overflow-y-auto flex flex-col justify-start items-start '>
                <nav className="relative flex justify-between items-center min-h-[6rem] p-[1rem] md:px-[4rem] w-screen">

                    <Link to='/' className="relative flex justify-start items-center h-full w-[100px]">
                        <img src= {logoMain} alt="" className='h-[100%] ' />
                    </Link>

                    {width < breakpoint ? <button className="flex"  onClick={() => activeMobileNav(true)}>
                        <AiOutlineMenu className='text-white font-[500] text-[24px]'/>
                    </button> : ''}

            
                    <ul className="hidden sm:flex items-center justify-between space-x-[2rem]">
                    <li className="flex justify-center items-center text-white font-[500] text-[13.5px] tracking-wide transition-all uppercase hover:text-mainGoldColor"> <a href="/aboutus">About Us</a> </li>
                        <li className="flex justify-center items-center text-white font-[500] text-[13.5px] tracking-wide transition-all uppercase hover:text-mainGoldColor"> <a href="/howitworks">How it works</a> </li>
                        <li className="flex justify-center items-center text-white font-[500] text-[13.5px] tracking-wide transition-all uppercase hover:text-mainGoldColor"><a href="/locations" >Locations</a></li>
                    </ul>
       

                    <Link to = "/signin" className="relative w-[100px] h-[33px] hidden justify-center items-center border bg-mainGoldColor border-mainGoldColor rounded space-x-1 sm:flex">
                        <p className="text-[14.5px] font-[500] tracking-wider text-white ">Login </p>
                        <RiLoginCircleFill className='text-white text-[17.5px] font-[500]  '/>
                    </Link> 
                </nav>

                {/* about us */}
                <div className='relative w-full flex flex-col justify-center items-center space-y-8 py-[6rem]'>
                    <div className='relative w-full px-[1rem] flex flex-col justify-start items-start space-y-4 md:max-w-[700px]'>
                        <h3 className='text-[22px] text-left text-mainGoldColor font-[500] uppercase md:max-w-[1000px]'>About The Dirty Angels</h3>

                        <div className='relative flex flex-col justify-start items-start space-y-4'>
                            <p className='text-[16px] text-white'>The Dirty Angels is a luxe escort and adult directory providing you with authentic, sensual and breathtaking experiences — with the safety and security of our state-of-the-art platform. Designed to be inclusive and supportive of independent escorts in new and innovative ways, our platform is a thoughtfully curated space for the sex work community. The team works endlessly to design the best possible user experience while simultaneously working to provide the sex work community with a dedicated and genuine support network for peers to connect. </p>
                            <p className='text-[16px] text-white'>We understand that entering the world of sex work or exploring escorts can be daunting — particularly if it's your first time.  With us, your privacy and safety are our number one priority. We work tirelessly to create discreet escort experiences centred around respect and connection. </p>
                            <p className='text-[16px] text-white'>We understand that entering the world of sex work and booking independent escorts can be daunting for some, particularly for those who are new to the industry. When you browse our platform, you can rest assured all our escorts have undergone a rigorous, manual verification process. This gives you peace of mind, confidence and security when you find escorts and babes you'd like to share an experience with. We do this to allow you peace of mind and to provide you with some security when you have found the escort you'd like to send a booking request to. </p>
                        </div>
                        
                    </div>                 
                </div>

                <footer className='relative w-full flex flex-col justify-center items-center space-y-10 px-[1rem] py-[3rem] border-t-mainGoldColor border-t-[2px]'>
                    <div className='relative w-full flex flex-col-reverse justify-center items-center space-y-[3rem] space-y-reverse sm:flex-row sm:justify-between sm:items-start sm:space-y-[0rem] sm:space-x-[4rem] sm:max-w-[400px] md:max-w-[700px]'>
                            <ul className='relative flex flex-col justify-center items-center space-y-8'>
                            <Link to='/' className="relative flex justify-start items-center h-full w-[100px]">
                                <img src= {logoMain} alt="" className='h-[100%] ' />
                            </Link>
                                <li className='text-[#9C9C9C] text-[14px]'>© The Dirty Angels 2023</li>
                            </ul>

                    
                            <ul className='relative flex flex-col justify-center items-center space-y-8'>
                                <li className='text-[15px] text-[#9C9C9C]'><Link to="/aboutus">About Us</Link></li>
                                <li className='text-[15px] text-[#9C9C9C]'><Link to="/contactus">Contact Us</Link></li>
                                <li className='text-[15px] text-[#9C9C9C]'><Link to="/signup">Book An Escort</Link></li>
                            </ul>
                    </div>
                </footer>
        </div>} 
    </div>
  )
}

export default Aboutus