import React from 'react'
import DataTable from "react-data-table-component"

const ReactDataTable = ({columns, data}) => {
 
    const paginationComponentOptions = {
        rowsPerPageText: false,
        rangeSeparatorText: 'of',
        selectAllRowsItem: false,
    };

    const customStyles = {
        rows: {
            style: {
                minHeight: '72px', // override the row height
                minWidth: '100%'
            },
        },
        headCells: {
            style: {
                backgroundColor: '#aa9348',
                color: 'white', 
                fontSize: '14px',
                fontWeight:'550px',
            },
        },
        cells: {
            style: {
                backgroundColor: 'white', 
            },
        },
    };
    
  return (
    <div className='relative w-full min-h-[100px] '>
        <div className='w-full'>
            <DataTable className='overflow-x-auto'
                columns = {columns}
                data = {data}
                customStyles={customStyles}
                pagination paginationComponentOptions={paginationComponentOptions}
            ></DataTable>
        </div>
    </div>
  )
}

export default ReactDataTable