import React from 'react'
import axios from 'axios';
import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {BsFillArrowLeftCircleFill} from "react-icons/bs"


const ModelDelete = () => {  


    const [toastId, setToastId] = useState(null); // Add toastId state variable

    const [toastDisplay, updatetoastDisplay] = useState('');


    //display all model images
    const [allimages, allimagesUpdate] = useState([]);
  
    useEffect(()=>{
        axios.get('/api/images')
        .then((response) => {
            allimagesUpdate(response.data.images);
        })
        .catch((error) => {
            console.log(error);
        });
    }, [])

  

    const modelToDel = async (imageName) =>{
      try {
        const response = await axios.delete('/api/delete/model', {
          data: { imageName },
        });
        const responseData = response.data

        if(responseData.message){
          updatetoastDisplay(responseData.message)
          setToastId(Date.now()); // Generate a new unique toastId
        }else{
          updatetoastDisplay(responseData.error)
          setToastId(Date.now()); // Generate a new unique toastId
        }
      }catch (error) {
        console.log(error) 
      }
    }

    useEffect(() => {

      if(toastDisplay){
        if(toastDisplay === 'Something went wrong'){
          toast.error(`${toastDisplay}, please refresh page.`, {
              position: 'top-center',
              autoClose: 5000,
              hideProgressBar: false,
              newestOnTop: true,
              closeOnClick: true,
              rtl: false,
              pauseOnFocusLoss: false,
              draggable: false,
              pauseOnHover: false,
              toastId: toastId, // Use the generated toastId as the toastId prop
          })
        }else{
          toast.success(`${toastDisplay}, please refresh page.`, {
            position: 'top-center',
            autoClose: 5000,
            hideProgressBar: false,
            newestOnTop: true,
            closeOnClick: true,
            rtl: false,
            pauseOnFocusLoss: false,
            draggable: false,
            pauseOnHover: false,
            toastId: toastId, // Use the generated toastId as the toastId prop
        })
        }
      }
  
    }, [toastDisplay, toastId]);

  return (
    <div className='relative overflow-hidden flex flex-col justify-start items-center bg-black text-white p-4 w-full min-h-screen'>
        <Link className='relative w-full text-left text-[20px]' to="/admin/dashboard"><BsFillArrowLeftCircleFill /></Link>
            
            <div className='relative w-full min-h-[35rem] flex flex-col justify-center items-center space-y-14 px-[1rem] py-[3rem]'>
                
                {/* all toast messages */}
                <div className='relative w-full '>
                    <ToastContainer            
                            className="relative w-full"
                    />
                </div>

                <div className='relative w-full min-h-[20rem] grid-container'>
                    {allimages.map((image, index) => (
                        <div key={`${image.ModelName}-${index}`} className='relative flex flex-col justify-start items-start space-y-[0.10rem] rounded-md p-4  hover:bg-[#0b0b0b] hover:border-[#0b0b0b] hover:transition-all'>
                            <img src={image.url} alt={`${image.ModelName}`} className='picture-size border border-black rounded-sm'/>
                            <h6 className='text-[14.50px] text-white font-[500] pt-[0.50rem]'>{image.ModelName}</h6>
                            <h6 className='text-[12.50px] text-[#bbbbbb]  font-[550]'>{image.Location}</h6>
                            <h6 className='text-[14px] text-mainGoldColor font-[550]'>{image.AverageFee}</h6>
                            <button onClick={() => modelToDel(image.imageName)} className='bg-red-600 w-[90px] h-[30px] text-white border-red-600 rounded-md' >Delete</button>
                        </div>
                    ))}
                </div>
            </div>
    </div>
  )
}

export default ModelDelete