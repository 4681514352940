import React from 'react'
import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { RiWhatsappFill } from "react-icons/ri";
import jwt_decode from "jwt-decode";
import axios from 'axios';
import { useGlobalContext } from '../../assets/context';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import logoMain from '../../images/iconword.svg'

import {GiHamburgerMenu} from "react-icons/gi"
import {RiNotification2Line} from "react-icons/ri"
import {AiFillHome} from "react-icons/ai" 
import {BiHistory} from "react-icons/bi" 
import {CiRollingSuitcase} from "react-icons/ci"  
import {BiMessageAlt} from "react-icons/bi"  
import {BiLogOut} from "react-icons/bi" 





import SideNav from '../components/SideNav';

const UserContactUs = () => {

    const [navActive, updateNavActive] = useState(null)
    const{fetchUserData, allUserMongoData, userNotifDataAll} = useGlobalContext();
    const [decodedData, updatedecodedData] = useState({});
    const [userContactUsForm, updateuserContactUsForm] = useState({
        UserConHeader: "", 
        UserConMessage: ""
    });

    const phoneNumber = '+447342518045';

    const openChat = () => {
      // Construct the WhatsApp chat link with the phone number
      const chatLink = `https://api.whatsapp.com/send?phone=${encodeURIComponent(phoneNumber)}`;
  
      // Open the chat link in a new window or tab
      window.open(chatLink, '_blank');
    };


    const userToken = sessionStorage.getItem('userInfo');

    const userConfig = {
        headers: {
          Authorization: `Bearer ${userToken}`,
        },
      };

    useState(()=>{
        fetchUserData()

        if(userToken){
            const decoded = jwt_decode(userToken);
            updatedecodedData(decoded.id)
        }
        
    }, [])

    const UserContatFormSub = async (e) =>{
        e.preventDefault()

        try {
            //send form data to backend
            const serverSend =  await axios.post('/api/user/contactus', {...userContactUsForm}, userConfig)
            const serverData = serverSend.data;

            //checks if data contains any errors
            if(serverData.errMsg){
                toast.error(serverData.errMsg)
            }else{
               toast.success(serverData.successMsg)  
               updateuserContactUsForm({
                    UserConHeader: "", 
                    UserConMessage: ""
                })
            }

        }catch (error) {
            console.log(error)
        } 

    }

    const hideNav = () =>{
        updateNavActive(false)
    }

    const clearSess = () =>{
        window.sessionStorage.clear();
    }

    const userDashLinks = [
        { url: '/user/dashboard', name: 'Dashboard', icon: <AiFillHome className='cursor-pointer text-[22px] text-[#d4b657] transition-all ease-in duration-300 hover:scale-[1.1] md:text-[25px]' /> },
        { url: '/user/bookmodel', name: 'Book Model', icon: <CiRollingSuitcase className='cursor-pointer text-[22px]  text-[#d4b657] transition-all ease-in duration-300 hover:scale-[1.1] md:text-[20px]' /> },
        { url: '/user/bookedmodels', name: 'Booked Models', icon: <BiHistory className='cursor-pointer text-[22px] text-[#d4b657] transition-all ease-in duration-300 hover:scale-[1.1] md:text-[25px]' /> },
        { url: '/user/Contactus', name: 'Contact Us', icon: <BiMessageAlt className='cursor-pointer text-[22px] text-[#d4b657] transition-all ease-in duration-300 hover:scale-[1.1] md:text-[25px]' /> },
        { url: '/signin', name: 'Sign Out', icon: <BiLogOut className='cursor-pointer text-[22px] text-[#d4b657] transition-all ease-in duration-300 hover:scale-[1.1] md:text-[25px]'/>, action: clearSess },
    ];

  return (
    <div className='relative flex flex-col overflow-x-hidden w-full min-h-screen bg-black text-white'>

        <SideNav hideNav = {hideNav} translateAction = {navActive ? `translate-x-[0px]` : `translate-x-[-500px]`} clearSess = {clearSess} userName={`Brad.`} backgroundColor = 'bacground-linearGradient' arrowCoLor = 'text-white' iconBorderColor = 'border-white' iconBgColor = 'bg-inherit' textColor = 'text-white'  links = {userDashLinks} SideNavuserName = {decodedData.UserName} />

        <div className='relative flex flex-col flex-grow w-full h-full py-2 px-4 overflow-hidden mb-[100px]'> 
            {/* <!-- ham and notification header --> */}
            <div className="relative w-full min-h-[100px] flex justify-center items-start">    
                <div className="relative w-full min-h-[50px] flex justify-between items-center">
                    {/* <!-- hamburger menu --> */}
                    <button onClick={() => updateNavActive(true) } className="relative">
                        <GiHamburgerMenu className='text-[20px] text-white' />
                    </button>   
                    
                    {/* <!-- manage notofication and balance --> */}
                    <div className='relative flex justify-end items-center space-x-4'>             
                        
                        <Link to="/user/notifications" className="relative flex justify-end items-center">
                            
                            <RiNotification2Line className='relative cursor-pointer text-white text-[18px]' />

                            {userNotifDataAll.length > 0 && <div className="flex absolute left-[8px] top-[-4px] w-[12px] h-[12px] bg-red-600 border-red-600 rounded-full justify-center items-center" >
                                <p className="relative text-[8px] text-white"> {userNotifDataAll.length} </p>
                            </div>}
                        </Link>
                    </div>
                </div>
            </div>

            {/* message box  */}
            <div className="relative min-h-[400px] w-full flex flex-col justify-start items-center py-5 mt-10">
                {/* all toast messages */}
                <div className='relative flex justify-center items-center w-[90%] sm:w-[490px] pb-5'>
                    <ToastContainer          
                            className="relative w-full"
                    />
                </div>           
                <div className="relative flex flex-col justify-start items-center space-y-3 w-[90%] min-h-[50px] bg-black ContactUsBoxShadow border-black rounded-lg pt-5 pb-8 px-5 sm:w-[490px]">
                    
                    <form action="" method="" onSubmit={(e)=> UserContatFormSub(e)} className="w-full">
                        {/* <!--message header --> */}
                        <div className="flex flex-col justify-start space-y-2 w-full">
                            <label className='text-white text-[14.50px]' htmlFor="">Message Header</label>
                            <input value={userContactUsForm.UserConHeader} name="UserConHeader" type="text" className="w-[100%] h-[45px] text-[16px] px-[5px] border border-[#272626] bg-inherit rounded-md focus:border-[#2e2d2d] focus:outline-none"  onChange={(e)=> updateuserContactUsForm({...userContactUsForm, [e.target.name]: e.target.value})}/>
                        </div>



                        {/* <!-- Your message --> */}
                        <div className="flex flex-col justify-start space-y-2 w-full pt-2">
                            <label className='text-white text-[14.50px]' htmlFor="">Message:</label>
                            <textarea value={userContactUsForm.UserConMessage} name="UserConMessage" className="resize-none w-[100%] min-h-[145px] text-[16px] px-[5px] py-2 border border-[#272626] bg-inherit rounded-md focus:border-[#2e2d2d] focus:outline-none" placeholder = "How can we help?" onChange={(e)=> updateuserContactUsForm({...userContactUsForm, [e.target.name]: e.target.value})} />
                        </div>

                        {/* <!-- send btn --> */}
                        <div className="w-full flex justify-center items-center pt-4">
                            <button type="submit" className="w-[150px] h-[48px] bg-inherit text-white border border-[#272626] rounded-md transition-all ease-in duration-300 hover:text-mainGoldColor">Send</button>
                        </div>
                    </form>
                    
                </div>

            </div>


            <div className='relative w-full min-h-[30px] flex flex-col justify-center items-center pt-[2rem]'>
                <p className='text-[14px] text-center text-[grey]'>Contact us on Whatsapp</p>
                <a href="#" className='relative w-[30px] h-[30px] md:w-[30px] md:h-[30px]' onClick={openChat}>
                    <RiWhatsappFill className='relative text-[#25D366] w-full h-full'/>
                </a>
            </div>

        </div>

        <footer className="relative flex-shrink-0 w-full h-[6rem] flex justify-center items-end py-[1rem]">
            <img src={logoMain} alt="" className="h-[50%] w-[30%] ssm:w-[120px] xl:w-[130px]" />
        </footer>
    </div>
  )
}

export default UserContactUs