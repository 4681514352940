import {useNavigate, Link} from 'react-router-dom'
import { useState, useEffect  } from 'react'
import logoMain from '../images/Iconblack.svg'
import axios from 'axios'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useGlobalContext } from '../assets/context';




const Signup = () => {

    const { successMessagesDefault, successMessageUpdate } = useGlobalContext(''); 

    const navigate = useNavigate();

    const [signupValues, setsignupValues] = useState({
        email: "",
        password: "",
        authcode: "",
        username: "",
    });

    const [errorMessagesDefault, errorMessageUpdate] = useState([]);

   
  
    //run function on form submission
    const signupSubmit = async (e) =>{
        e.preventDefault();
 
        try {
            //send form data to backend
            const regResponse =  await axios.post('/api/signup', {...signupValues,})
            const regResponseData = regResponse.data;

            
            //checks if data contains any errors
            if(regResponseData.errors){
                //update input field values to be displayed alongside errors
                setsignupValues({
                    email: regResponseData.email,
                    password: "",
                    authcode: regResponseData.authcode,
                    username: regResponseData.username,
                })

                //destructure error messages from json data
                const newErrorMessages = regResponseData.errors.map(error => error.msg);

                // Update with new error messages
                errorMessageUpdate([...newErrorMessages]);  
            }else{
                //success message from json data
                const succRegMsg = regResponseData.successMsg;  
                successMessageUpdate(succRegMsg) 
                // Navigate to the Signin component
                navigate('/signin'); 
                      
                   
            }
           
                          
        } catch (error){
            console.log('error')
        }    
    } 

    //call function to display toast error messages and display previous input values
    useEffect(() => {

        // Clear previous toast messages
        toast.dismiss();

        const displayErrorMessages = () => {
          errorMessagesDefault.forEach((errorMessage) => {
            if (errorMessage) {
                toast.error(errorMessage, {
                    position:"top-center",
                    autoClose: 5000,
                    hideProgressBar:false,
                    newestOnTop:true,
                    closeOnClick:true,
                    rtl:false,
                    pauseOnFocusLoss:false,
                    draggable:false,
                    pauseOnHover: false,   
                })
            }
          });
        };    

        displayErrorMessages();

    }, [errorMessagesDefault]);
    

  return (
     //whole container
     <div className="relative overflow-hidden flex flex-col justify-center items-center bg-white text-[#000000d9] w-full min-h-screen ">
        {/* sign up */}
        <div className='relative w-full h-full py-[3rem] px-5 flex flex-col justify-center items-center space-y-[1rem] max-w-[500px]'>
            <Link to='/' className="relative flex justify-start items-center h-full w-[100px]">
                <img src= {logoMain} alt="" className='h-[50%] ' />
            </Link>
            <h1 className='text-[24px] text-center text-[#000000d9] font-[500] '>Create your Dirty Angels account</h1>
            <p className='text-[15.50px] text-center text-[#000000d9] font-[450]'>Sign up to access thousands of Dirty Angels.</p>

            
           
            <form action="" method='' className='w-full'  onSubmit={(e)=> signupSubmit(e)}>
                <div className='relative w-full flex flex-col justify-start items-center space-y-8 py-[2rem]'>
                    
                        {/* all toast messages */}
                        <div className='relative w-full '>
                            <ToastContainer             
                                  className="relative w-full"
                            />
                        </div>

                        <div className='relative w-full flex flex-col justify-start items-start space-y-2 '>
                            <label htmlFor="" className='text-[15px] text-left text-[#000000d9] font-[450]'>Username</label>
                            <input value={signupValues.username} type="text" name='username' className='bg-[#fff] px-2 border-[#d9d9d9] border-[1px] rounded-[6px] w-full h-[45px] text-black text-[16px] focus:outline-[0.50px] focus:outline-[#d4b657] focus:transition-all hover:border-[#c3c3c3]' onChange={(e)=> setsignupValues({...signupValues, [e.target.name]:e.target.value})} />
                        </div>

                        <div className='relative w-full flex flex-col justify-start items-start space-y-2 '>
                            <label htmlFor="" className='text-[15px] text-left text-[#000000d9] font-[450]'>Email</label>
                            <input value={signupValues.email} type="text" name='email' className='bg-[#fff] px-2 border-[#d9d9d9] border-[1px] rounded-[6px] w-full h-[45px] text-black text-[16px] focus:outline-[0.50px] focus:outline-[#d4b657] focus:transition-all hover:border-[#c3c3c3]' onChange={(e)=> setsignupValues({...signupValues, [e.target.name]:e.target.value})} />
                        </div>

                        <div className='relative w-full flex flex-col justify-start items-start space-y-2 '>
                            <label htmlFor="" className='text-[15px] text-left text-[#000000d9] font-[450]'>Password</label>
                            <input value={signupValues.password} type="password" name='password' className='bg-[#fff] px-2 border-[#d9d9d9] border-[1px] rounded-[6px] w-full h-[45px] text-black text-[16px] focus:outline-[0.50px] focus:outline-[#d4b657] focus:transition-all hover:border-[#c3c3c3]' onChange={(e)=> setsignupValues({...signupValues, [e.target.name]:e.target.value})}/>
                        </div>

                        <div className='relative w-full flex flex-col justify-start items-start space-y-2 '>
                            <label htmlFor="" className='text-[15px] text-left text-[#000000d9] font-[450]'>Referral Code</label>
                            <input value={signupValues.authcode} type="text"  name='authcode' className='bg-[#fff] px-2 border-[#d9d9d9] border-[1px] rounded-[6px] w-full h-[45px] text-black text-[16px] focus:outline-[0.50px] focus:outline-[#d4b657] focus:transition-all hover:border-[#c3c3c3]' onChange={(e)=> setsignupValues({...signupValues, [e.target.name]:e.target.value})} />
                        </div>
    
                        <button type='submit' className='bg-[#1c1c1c] text-white border-[#1c1c1c] rounded-md flex justify-center items-center text-center w-full h-[40px] hover:cursor-pointer hover:bg-[#3c3c3c] hover:transition-all'>Sign Up</button>
                    

                    <p className='text-[#858585] text-[16.50px] text-center font-[450]'>Already have an account? <Link to="/signin" className='text-[#f0ab15] text-[16.50px] text-center font-[450]'>Sign in</Link></p>
                </div>
            </form>

        </div>
     </div>
  )
}

export default Signup;




