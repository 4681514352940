import React from 'react'
import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import jwt_decode from "jwt-decode";
import { useGlobalContext } from '../../assets/context';
import logoMain from '../../images/iconword.svg'

import {GiHamburgerMenu} from "react-icons/gi"
import {RiNotification2Line} from "react-icons/ri"
import {AiFillHome} from "react-icons/ai" 
import {BiHistory} from "react-icons/bi" 
import {CiRollingSuitcase} from "react-icons/ci"  
import {BiMessageAlt} from "react-icons/bi"  
import {BiLogOut} from "react-icons/bi" 
import {AiOutlinePlus} from "react-icons/ai"
import {BsFillArrowRightCircleFill} from "react-icons/bs"




import SideNav from '../components/SideNav';



const UserDashboard = () => {

    const [navActive, updateNavActive] = useState(null)
    const{fetchUserData, allUserMongoData, userNotifDataAll} = useGlobalContext();
    const [decodedData, updatedecodedData] = useState({});

    const userToken = sessionStorage.getItem('userInfo');

    useState(()=>{
        fetchUserData()

        if(userToken){
            const decoded = jwt_decode(userToken);
            updatedecodedData(decoded.id)
        }
        
    }, [])
   

    const hideNav = () =>{
    updateNavActive(false)
    }

    const clearSess = () =>{
    window.sessionStorage.clear();
    }

    const userDashLinks = [
        { url: '/user/dashboard', name: 'Dashboard', icon: <AiFillHome className='cursor-pointer text-[22px] text-[#d4b657] transition-all ease-in duration-300 hover:scale-[1.1] md:text-[25px]' /> },
        { url: '/user/bookmodel', name: 'Book Model', icon: <CiRollingSuitcase className='cursor-pointer text-[22px]  text-[#d4b657] transition-all ease-in duration-300 hover:scale-[1.1] md:text-[20px]' /> },
        { url: '/user/bookedmodels', name: 'Booked Models', icon: <BiHistory className='cursor-pointer text-[22px] text-[#d4b657] transition-all ease-in duration-300 hover:scale-[1.1] md:text-[25px]' /> },
        { url: '/user/Contactus', name: 'Contact Us', icon: <BiMessageAlt className='cursor-pointer text-[22px] text-[#d4b657] transition-all ease-in duration-300 hover:scale-[1.1] md:text-[25px]' /> },
        { url: '/signin', name: 'Sign Out', icon: <BiLogOut className='cursor-pointer text-[22px] text-[#d4b657] transition-all ease-in duration-300 hover:scale-[1.1] md:text-[25px]'/>, action: clearSess },
    ];

  return (
    <div className='relative flex flex-col overflow-x-hidden w-full min-h-screen bg-black text-white'>

        <SideNav hideNav = {hideNav} translateAction = {navActive ? `translate-x-[0px]` : `translate-x-[-500px]`} clearSess = {clearSess} userName={`Brad.`} backgroundColor = 'bacground-linearGradient' arrowCoLor = 'text-white' iconBorderColor = 'border-white' iconBgColor = 'bg-inherit' textColor = 'text-white'  links = {userDashLinks} SideNavuserName = {decodedData.UserName} />

        <div className='relative flex flex-col flex-grow w-full h-full py-2 px-4 overflow-hidden mb-[100px]'> 
            {/* <!-- ham and notification header --> */}
            <div className="relative w-full min-h-[100px] flex justify-center items-start">    
                <div className="relative w-full min-h-[50px] flex justify-between items-center">
                    {/* <!-- hamburger menu --> */}
                    <button onClick={() => updateNavActive(true) } className="relative">
                        <GiHamburgerMenu className='text-[20px] text-white' />
                    </button>   
                    
                    {/* <!-- manage notofication and balance --> */}
                    <div className='relative flex justify-end items-center space-x-4'>
                        
                        <Link to="/user/deposit" className="relative flex justify-end items-center">
                            <div className="relative flex justify-center items-center" >
                                <p className="relative text-[14px] text-white hover:text-mainGoldColor">$ {allUserMongoData.balance} </p>
                            </div>
                        </Link>                  
                        
                        <Link to="/user/notifications" className="relative flex justify-end items-center">
                            
                            <RiNotification2Line className='relative cursor-pointer text-white text-[18px]' />

                            {userNotifDataAll.length > 0 && <div className="flex absolute left-[8px] top-[-4px] w-[12px] h-[12px] bg-red-600 border-red-600 rounded-full justify-center items-center" >
                                <p className="relative text-[8px] text-white"> {userNotifDataAll.length} </p>
                            </div>}
                        </Link>
                    </div>
                </div>
            </div> 

            {/* section */}
            <div className='relative min-h-[500px] w-full flex flex-col justify-start items-center space-y-4 py-[2rem]'>


                {/* <!-- vip updgrade --> */}
                <div className="relative w-[100%] min-h-[100px] bacground-linearGradient3 rounded-lg flex flex-col justify-center items-center space-y-4 px-5 py-5 sm:w-[500px] xl:w-[600px]">
                    
                    <div className='w-full relative flex justify-start items-start space-x-1'>
                        <h3 className="font-[550] text-[19.50px] text-white text-center md:text-[23px]">Upgrade your account For VIP Benefits.</h3>
                        <AiOutlinePlus className=' text-[17px] text-[#d4b657]' />
                    </div>
                    
                    <ul className='w-full relative flex flex-col justify-start items-start space-y-2'>

                        <li className='relative flex justify-start items-start space-x-1'>
                            <AiOutlinePlus className=' text-[13px] text-[#d4b657]' />
                           <p className='relative text-[15.5px]'>Access to more models.</p> 
                           
                        </li>

                        <li className='relative flex justify-start items-start space-x-1'>
                            <AiOutlinePlus className=' text-[13px] text-[#d4b657]' />
                           <p className='relative text-[15.5px]'>Video calls prior to meet up.</p> 
                        </li>

                        <li className='relative flex justify-start items-start space-x-1'>
                            <AiOutlinePlus className=' text-[13px] text-[#d4b657]' />
                           <p className='relative text-[15.5px]'>Discounted rates on multiple bookings.</p> 
                        </li>

                        <li className='relative flex justify-start items-start space-x-1'>
                            <AiOutlinePlus className=' text-[13px] text-[#d4b657]' />
                           <p className='relative text-[15.5px]'>Priority Booking.</p> 
                        </li>

                    </ul>

                    <Link to= '/user/deposit' className='bg-[#1c1c1c] text-white border-[#1c1c1c] rounded-md flex justify-center items-center space-x-1 text-center w-full h-[40px] hover:cursor-pointer hover:bg-[#2a2a2a] transition-all'>
                        <p>Upgrade</p>
                        <AiOutlinePlus className=' text-[13px] text-[#d4b657]' />
                    </Link>
                </div>

                {/* proceed with Starter Account */}
                <div className='relative w-[100%] min-h-[100px] bacground-linearGradient2 rounded-lg flex flex-col justify-center items-center space-y-4 px-5 py-5 sm:w-[500px] xl:w-[600px]'>
                    <div className='w-full relative flex justify-center items-start'>
                        <h3 className="font-[550] text-[19.50px] text-white text-center md:text-[23px]">Proceed With Starter Account</h3>
                    </div>

                    <Link to= '/user/bookmodel' className='bg-[#1c1c1c] text-white border-[#1c1c1c] rounded-md flex justify-center items-center space-x-2 text-center w-full h-[40px] hover:cursor-pointer hover:bg-[#2a2a2a] transition-all'>
                        <p>Book Models</p>
                        <BsFillArrowRightCircleFill className=' text-[13px] text-[#d4b657]' />
                    </Link>
                </div>

            </div>
        </div> 

        <footer className="relative flex-shrink-0 w-full h-[6rem] flex justify-center items-end py-[1rem]">
            <img src={logoMain} alt="" className="h-[50%] w-[30%] ssm:w-[120px] xl:w-[130px]" />
        </footer>
    </div>
  )
}

export default UserDashboard