import React from 'react'
import axios from 'axios';
import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useGlobalContext } from '../../assets/context';
import 'react-toastify/dist/ReactToastify.css';
import {GiHamburgerMenu} from "react-icons/gi"
import {GrNotification} from "react-icons/gr"

import {AiFillHome} from "react-icons/ai" 
import {GrDocumentUpdate} from "react-icons/gr" 
import {AiFillDelete} from "react-icons/ai"  
import {TbBrandBooking} from "react-icons/tb"  
import {RiLuggageDepositLine} from "react-icons/ri"  
import {FaUsers} from "react-icons/fa"
import {BiLogOut} from "react-icons/bi" 


import SideNav from '../components/SideNav';


const AdminDashboard = () => {

  
  const {allUsersMongoData, updateallUsersMongoData, allUsersCloudinaryData, UpdateallUsersCloudinaryData, fetchAdminData } = useGlobalContext()
  const [navActive, updateNavActive] = useState(null)




  useEffect(()=>{
    fetchAdminData()
  }, []);

  
  const hideNav = () =>{
    updateNavActive(false)
  }

  const clearSess = () =>{
    window.sessionStorage.clear();
  }

  const AdminSideNav = [
    { url: '/admin/dashboard', name: 'Dashboard', icon: <AiFillHome className='cursor-pointer text-[22px] text-white transition-all ease-in duration-300 hover:scale-[1.1] md:text-[25px]' /> },
    { url: '/admin/modelupdate', name: 'Update Model', icon: <GrDocumentUpdate className='cursor-pointer text-[16px] bg-white text-white transition-all ease-in duration-300 hover:scale-[1.1] md:text-[20px]' /> },
    { url: '/admin/modeldelete', name: 'Delete Model', icon: <AiFillDelete className='cursor-pointer text-[22px] text-white transition-all ease-in duration-300 hover:scale-[1.1] md:text-[25px]' /> },
    { url: '/admin/modelbookings', name: 'Model Bookings', icon: <TbBrandBooking className='cursor-pointer text-[22px] text-white transition-all ease-in duration-300 hover:scale-[1.1] md:text-[25px]' /> },
    { url: '/admin/managedeposits', name: 'Manage Deposits', icon: <RiLuggageDepositLine className='cursor-pointer text-[22px] text-white transition-all ease-in duration-300 hover:scale-[1.1] md:text-[25px]' /> },
    { url: '/admin/manageusers', name: 'Manage Users', icon: <FaUsers className='cursor-pointer text-[22px] text-white transition-all ease-in duration-300 hover:scale-[1.1] md:text-[25px]' /> },
    { url: '/signin', name: 'Sign Out', icon: <BiLogOut className='cursor-pointer text-[22px] text-white transition-all ease-in duration-300 hover:scale-[1.1] md:text-[25px]' />, action: clearSess },
  ]



  return (
    <div className='absolute overflow-x-hidden w-full min-h-full bg-white'>
        
      
        <SideNav hideNav = {hideNav} translateAction = {navActive ? `translate-x-[0px]` : `translate-x-[-500px]`} clearSess = {clearSess} backgroundColor = 'bg-black' arrowCoLor = 'text-white' iconBorderColor = 'border-white' iconBgColor = 'bg-black' textColor = 'text-white' links = {AdminSideNav} />
        

        <div className='relative w-full h-full p-2 overflow-hidden mb-[100px]'> 
          {/* <!-- ham and notification header --> */}
          <div className="relative w-full min-h-[100px] flex justify-center items-start">    
              <div className="relative w-full min-h-[50px] flex justify-between items-center">
                  {/* <!-- hamburger menu --> */}
                  <button onClick={() => updateNavActive(true) } className="relative">
                      <GiHamburgerMenu className='text-[20px] text-black' />
                  </button>
                  
                  {/* <!-- manage notofication icon --> */}
                  <Link to="/admin/notifications" className="relative flex justify-end items-center">

                      <GrNotification className='relative cursor-pointer text-black text-[20px]' />
                  </Link>
              </div>
          </div> 

          {/* dashboard information display */}
          <div className='relative w-full min-h-[100px] flex flex-col justify-center items-center space-y-6 px-5 py-5'>
            {/* <!-- active users --> */}
            <div className="relative w-[100%] min-h-[100px] bg-black border border-white rounded-lg flex flex-col justify-center items-center px-5 py-5 md:w-[600px] xl:w-[900px]">
                <h3 className="font-[550] text-[20.50px] text-white text-center md:text-[23px]">Active Users</h3>
                <p className="font-[550] text-[19.50px] text-center text-[#dfe3e7] md:text-[20px]"> {allUsersMongoData.length}  </p>
            </div>

            {/* <!-- All models --> */}
                <div className="relative w-[100%] min-h-[100px] bg-black border border-white rounded-lg flex flex-col justify-center items-center px-5 py-5 md:w-[600px] xl:w-[900px]">
                <h3 className="font-[550] text-[20.50px] text-white text-center md:text-[23px]">All Models</h3>
                <p className="font-[550] text-[19.50px] text-center text-[#dfe3e7] md:text-[20px]">  {allUsersCloudinaryData.length}  </p>
            </div>

            {/* Active bookings */}
            <div className="relative w-[100%] min-h-[100px] bg-black border border-white rounded-lg flex flex-col justify-center items-center px-5 py-5 md:w-[600px] xl:w-[900px]">
                <h3 className="font-[550] text-[20.50px] text-white text-center md:text-[23px]">Active Bookings </h3>
                <p className="font-[550] text-[19.50px] text-center text-[#dfe3e7] md:text-[20px]"> 0 </p>
            </div>

          </div>

        </div>



    </div>
  )
}

export default AdminDashboard