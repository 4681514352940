import React from 'react'
import { useState, useEffect } from 'react';
import axios from 'axios';
import jwt_decode from "jwt-decode";
import { Link } from 'react-router-dom';
import Viewport from '../components/Viewport';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useGlobalContext } from '../../assets/context';

import {GiConsoleController, GiHamburgerMenu} from "react-icons/gi"
import {RiNotification2Line} from "react-icons/ri"
import {AiFillHome} from "react-icons/ai" 
import {GrDocumentUpdate} from "react-icons/gr" 
import {AiFillDelete} from "react-icons/ai"  
import {TbBrandBooking} from "react-icons/tb"  
import {RiLuggageDepositLine} from "react-icons/ri"  
import {FaUsers} from "react-icons/fa"
import {BiLogOut} from "react-icons/bi" 
import { ImCancelCircle } from "react-icons/im";

import SideNav from '../components/SideNav';
import ReactDataTable from '../components/ReactDataTable';

const AdminNotifications = () => {
  const [navActive, updateNavActive] = useState(null);
  const {AdminNotifData, fetchAdminData } = useGlobalContext();
  const [activeForm, updateactiveForm] = useState(null)
  const [notifForm, notifFormUpdate] = useState({
      anemail: "",
      anheader: "",
      anmessage: "",
  })


  // Get the token from sessionStorage
  const adminToken = sessionStorage.getItem('adminInfo');

  useEffect(()=>{
    fetchAdminData()
  }, []);

  const hideNav = () =>{
    updateNavActive(false)
  }

  const clearSess = () =>{
    window.sessionStorage.clear();
  }

  const submitNotif = async (e) =>{
    e.preventDefault()

    try {
      const response = await axios.post('/api/admin/updatenotifications', {...notifForm},
      { 
          headers: {
              Authorization: `Bearer ${adminToken}`,
          },
      }) 
      updateactiveForm(false)
      const responseData = response.data;

      if(responseData.errMsg){
          toast.error(responseData.errMsg, {
            position:"top-center",
            autoClose: 3000,
            hideProgressBar:false,
            newestOnTop:true,
            closeOnClick:true,
            rtl:false,
            pauseOnFocusLoss:false,
            draggable:false,
            pauseOnHover: false,   
        })
      }
      else{
        toast.success(responseData.successMsg, {
          position:"top-center",
          autoClose: 3000,
          hideProgressBar:false,
          newestOnTop:true,
          closeOnClick:true,
          rtl:false,
          pauseOnFocusLoss:false,
          draggable:false,
          pauseOnHover: false,   
      })
          setTimeout(() => {
            window.location.reload();
          }, 5000);
      }

    } catch (error) {
        console.log(error)
    }

  }

  //delete notification on button click
  const deleteNotif = async (userId) =>{
    try {
        const response = await axios.post('/api/admin/notifdelete', {userId: userId},
        { 
            headers: {
                Authorization: `Bearer ${adminToken}`,
            },
        }) 

        const responseData = response.data.successMsg;

        toast.success(responseData, {
          position:"top-center",
          autoClose: 3000,
          hideProgressBar:false,
          newestOnTop:true,
          closeOnClick:true,
          rtl:false,
          pauseOnFocusLoss:false,
          draggable:false,
          pauseOnHover: false, 
        })

        setTimeout(() => {
          window.location.reload();
        }, 5000);

    } catch (error) {
        console.log(error)
    }
  }

  const AdminSideNav = [
      { url: '/admin/dashboard', name: 'Dashboard', icon: <AiFillHome className='cursor-pointer text-[22px] text-white transition-all ease-in duration-300 hover:scale-[1.1] md:text-[25px]' /> },
      { url: '/admin/modelupdate', name: 'Update Model', icon: <GrDocumentUpdate className='cursor-pointer text-[16px] bg-white text-white transition-all ease-in duration-300 hover:scale-[1.1] md:text-[20px]' /> },
      { url: '/admin/modeldelete', name: 'Delete Model', icon: <AiFillDelete className='cursor-pointer text-[22px] text-white transition-all ease-in duration-300 hover:scale-[1.1] md:text-[25px]' /> },
      { url: '/admin/modelbookings', name: 'Model Bookings', icon: <TbBrandBooking className='cursor-pointer text-[22px] text-white transition-all ease-in duration-300 hover:scale-[1.1] md:text-[25px]' /> },
      { url: '/admin/managedeposits', name: 'Manage Deposits', icon: <RiLuggageDepositLine className='cursor-pointer text-[22px] text-white transition-all ease-in duration-300 hover:scale-[1.1] md:text-[25px]' /> },
      { url: '/admin/manageusers', name: 'Manage Users', icon: <FaUsers className='cursor-pointer text-[22px] text-white transition-all ease-in duration-300 hover:scale-[1.1] md:text-[25px]' /> },
      { url: '/signin', name: 'Sign Out', icon: <BiLogOut className='cursor-pointer text-[22px] text-white transition-all ease-in duration-300 hover:scale-[1.1] md:text-[25px]' />, action: clearSess }
  ]

  const columns = [
    {
        name: 'Date',
        selector: (row) => row.date,
        cell: (row) => (
          <div style={{ whiteSpace: 'normal' }}>
            {new Date(row.date).toLocaleDateString('en-GB')}
          </div>
        )
    },
    {
        name: 'Email', 
        selector: row => row.notifemail,
        cell: (row) =>(
            <div style={{whiteSpace:'normal'}}>
                {row.notifemail}
            </div>
        )
    },
    {
          name: 'Header', 
          selector: row => row.notifheader,
          cell: (row) =>(
              <div style={{whiteSpace:'normal'}}>
                  {row.notifheader}
              </div>
          )
    },
    {
      name: 'Message', 
      selector: row => row.notifmessage,
      cell: (row) =>(
          <div style={{whiteSpace:'normal'}}>
              {row.notifmessage}
          </div>
      )
    },
    {
        name: 'Action', 
        cell: (row) =>(
            <div style={{whiteSpace:'normal', display:'flex', justifyItems: 'center', alignItems: 'center'}}>
                <button onClick={()=> deleteNotif(row.notifemail)} className="bg-red-700 text-white flex justify-center items-center min-w-[60px] h-[25px] border border-white rounded-md p-2">Delete</button>
            </div>
        )
    },
  ];
  return (
    <div className='relative flex flex-col overflow-x-hidden w-full min-h-screen bg-black text-white'>

        <SideNav hideNav = {hideNav} translateAction = {navActive ? `translate-x-[0px]` : `translate-x-[-500px]`} clearSess = {clearSess} backgroundColor = 'bg-black' arrowCoLor = 'text-white' iconBorderColor = 'border-white' iconBgColor = 'bg-black' textColor = 'text-white' links = {AdminSideNav}  />

        {activeForm && <div className='absolute w-full min-h-[100%] top-0 left-0 flex justify-center items-center bg-[#111111c9] z-50'>
            <div className='relative min-h-[400px] w-[90%] flex flex-col justify-start items-center space-y-4 bg-[#d6d6d6] p-4 border border-[#d6d6d6] rounded-md sm:w-[400px] ssm:w-[80%]'>

                <div className='relative w-full flex justify-end items-center'>
                    <button className="relative " onClick = {()=>{updateactiveForm(false)}}  >
                        <ImCancelCircle className='cursor-pointer text-[22px] text-black transition-all ease-in duration-300 hover:scale-[1.1] md:text-[25px]' />
                    </button>
                </div>

                <form action="" className='w-full' onSubmit={(e) => submitNotif(e)}>
                    <div className='relative w-[100%] min-h-[54px] flex flex-col justify-start items-center space-y-3'>
                        <div className='relative w-full flex flex-col justify-start items-start space-y-2 '>
                            <label htmlFor="email" className='text-[15px] text-left text-[#000000d9] font-[450]'>Email</label>
                            <input type="email" name='anemail' className='bg-black px-2 border-[#d9d9d9] border-[1px] rounded-[6px] w-full h-[45px] text-white text-[16px] focus:outline-[0.50px] focus:outline-[#d4b657] focus:transition-all hover:border-[#c3c3c3]' onChange={(e)=> notifFormUpdate({...notifForm, [e.target.name]: e.target.value})}  />
                        </div>

                        <div className='relative w-full flex flex-col justify-start items-start space-y-2 '>
                            <label htmlFor="header" className='text-[15px] text-left text-[#000000d9] font-[450]'>Header</label>
                            <input type="text" name='anheader' className='bg-black px-2 border-[#d9d9d9] border-[1px] rounded-[6px] w-full h-[45px] text-white text-[16px] focus:outline-[0.50px] focus:outline-[#d4b657] focus:transition-all hover:border-[#c3c3c3]' onChange={(e)=> notifFormUpdate({...notifForm, [e.target.name]: e.target.value})} />
                        </div>

                        <div className='relative w-full flex flex-col justify-start items-start space-y-2 '>
                            <label htmlFor="type" className='text-[15px] text-left text-[#000000d9] font-[450]'>Message</label>
                            <textarea cols="5" rows="5" type="text" name='anmessage' className='bg-black px-2 border-[#d9d9d9] border-[1px] rounded-[6px] w-full resize-none text-white text-[16px] focus:outline-[0.50px] focus:outline-[#d4b657] focus:transition-all hover:border-[#c3c3c3]' onChange={(e)=> notifFormUpdate({...notifForm, [e.target.name]: e.target.value})} />
                        </div>
                    </div>
                    

                    <div className='flex justify-center items-center pt-[1rem]'>
                        <button type='submit' className='min-w-[120px] min-h-[25px] p-2 font-[550] flex justify-center items-center bg-white text-black border border-white rounded-md'>Update</button>
                    </div>   
                </form>

            </div>
        </div>}


        <div className='relative flex flex-col flex-grow w-full h-full py-2 px-4 overflow-hidden mb-[100px]'> 
            {/* <!-- ham and notification header --> */}
            <div className="relative w-full min-h-[100px] flex justify-center items-start">    
                <div className="relative w-full min-h-[50px] flex justify-between items-center">
                    {/* <!-- hamburger menu --> */}
                    <button onClick={() => updateNavActive(true) } className="relative">
                        <GiHamburgerMenu className='text-[20px] text-white' />
                    </button>   
                    
                    {/* <!-- manage notofication and balance --> */}
                    <div className='relative flex justify-end items-center space-x-4'>
                        
                        <Link to="/admin/notifications" className="relative flex justify-end items-center">          
                            <RiNotification2Line className='relative cursor-pointer text-white text-[18px]' />
                        </Link>
                    </div>
                </div>
            </div> 

            <div className='relative min-h-[500px] w-full flex flex-col justify-start items-center space-y-4 py-[2rem]'>
                <p className='relative w-full text-center text-white'>Manage Notifications</p>

                <div className='relative min-h-[60px] w-full flex justify-center items-center '>
                    <button onClick={()=> {updateactiveForm(true)}} className='min-w-[120px] min-h-[25px] p-2 flex justify-center items-center bg-white text-black border border-white rounded-md'>Update Notifications</button>
                </div> 
                
                {/* all toast messages */}
                <div className='relative w-full '>
                    <ToastContainer          
                            className="relative w-full"
                    />
                </div>

                {/* table start */}
                <div className='relative max-w-full flex justify-center items-center '>
                    <ReactDataTable columns = {columns}  data ={AdminNotifData}/>   
                </div>
               

            </div>
        </div>

    </div>
  )
}

export default AdminNotifications