import React from 'react'
import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useGlobalContext } from '../../assets/context';
import jwt_decode from "jwt-decode";
import 'react-toastify/dist/ReactToastify.css';

import {GiHamburgerMenu} from "react-icons/gi"
import {RiNotification2Line} from "react-icons/ri"
import {AiFillHome} from "react-icons/ai" 
import {BiHistory} from "react-icons/bi" 
import {CiRollingSuitcase} from "react-icons/ci"  
import {BiMessageAlt} from "react-icons/bi"  
import {BiLogOut} from "react-icons/bi" 


import SideNav from '../components/SideNav';
import ReactDataTable from '../components/ReactDataTable';

const UserNotifications = () => {
    const{fetchUserData, allUserMongoData, userNotifDataAll} = useGlobalContext();
    const [navActive, updateNavActive] = useState(null)
    const [decodedData, updatedecodedData] = useState({});


    const userToken = sessionStorage.getItem('userInfo');

    useEffect(()=>{
        fetchUserData()

        if(userToken){
            const decoded = jwt_decode(userToken);
            updatedecodedData(decoded.id)  
  
        }      
    }, [])
 

    const hideNav = () =>{
    updateNavActive(false)
    }

    const clearSess = () =>{
    window.sessionStorage.clear();
    }

    const userDashLinks = [
        { url: '/user/dashboard', name: 'Dashboard', icon: <AiFillHome className='cursor-pointer text-[22px] text-[#d4b657] transition-all ease-in duration-300 hover:scale-[1.1] md:text-[25px]' /> },
        { url: '/user/bookmodel', name: 'Book Model', icon: <CiRollingSuitcase className='cursor-pointer text-[22px]  text-[#d4b657] transition-all ease-in duration-300 hover:scale-[1.1] md:text-[20px]' /> },
        { url: '/user/bookedmodels', name: 'Booked Models', icon: <BiHistory className='cursor-pointer text-[22px] text-[#d4b657] transition-all ease-in duration-300 hover:scale-[1.1] md:text-[25px]' /> },
        { url: '/user/Contactus', name: 'Contact Us', icon: <BiMessageAlt className='cursor-pointer text-[22px] text-[#d4b657] transition-all ease-in duration-300 hover:scale-[1.1] md:text-[25px]' /> },
        { url: '/signin', name: 'Sign Out', icon: <BiLogOut className='cursor-pointer text-[22px] text-[#d4b657] transition-all ease-in duration-300 hover:scale-[1.1] md:text-[25px]'/>, action: clearSess },
    ];

    const columns = [
        {
            name: 'Date', 
            selector: row => row.date
        },
        {
            name: 'Header', 
            selector: row => row.notifheader
        },
        {
            name: 'Message', 
            selector: row => row.notifmessage,
            cell: (row) =>(
                <div style={{whiteSpace:'normal'}}>
                        {row.notifmessage}
                </div>
            )
        },
    ];

  return (
    <div className='relative flex flex-col overflow-x-hidden w-full min-h-screen bg-black text-white'>

        <SideNav hideNav = {hideNav} translateAction = {navActive ? `translate-x-[0px]` : `translate-x-[-500px]`} clearSess = {clearSess} userName={`Brad.`} backgroundColor = 'bacground-linearGradient' arrowCoLor = 'text-white' iconBorderColor = 'border-white' iconBgColor = 'bg-inherit' textColor = 'text-white'  links = {userDashLinks} SideNavuserName = {decodedData.UserName} />

        <div className='relative flex flex-col flex-grow w-full h-full py-2 px-4 overflow-hidden mb-[100px]'> 
            {/* <!-- ham and notification header --> */}
            <div className="relative w-full min-h-[100px] flex justify-center items-start">    
                <div className="relative w-full min-h-[50px] flex justify-between items-center">
                    {/* <!-- hamburger menu --> */}
                    <button onClick={() => updateNavActive(true) } className="relative">
                        <GiHamburgerMenu className='text-[20px] text-white' />
                    </button>   
                    
                    {/* <!-- manage notofication and balance --> */}
                    <div className='relative flex justify-end items-center space-x-4'>
                        
                        <Link to="/user/deposit" className="relative flex justify-end items-center">
                            <div className="relative flex justify-center items-center" >
                                <p className="relative text-[14px] text-white hover:text-mainGoldColor">$ {allUserMongoData.balance} </p>
                            </div>
                        </Link>                 
                        
                        <Link to="/user/notifications" className="relative flex justify-end items-center">
                            
                            <RiNotification2Line className='relative cursor-pointer text-white text-[18px]' />

                            {userNotifDataAll.length > 0 && <div className="flex absolute left-[8px] top-[-4px] w-[12px] h-[12px] bg-red-600 border-red-600 rounded-full justify-center items-center" >
                                <p className="relative text-[8px] text-white"> {userNotifDataAll.length} </p>
                            </div>}
                        </Link>
                    </div>
                </div>
            </div> 

            <div className='relative min-h-[500px] w-full flex flex-col justify-start items-center space-y-4 py-[2rem]'>              
                <p className='relative w-full text-center text-white'>Notifications</p>

                {/* table start */}
                <div className='relative max-w-full flex justify-center items-center '>
                    <ReactDataTable columns = {columns}  data = {userNotifDataAll}  />   
                </div>  
            </div>
            
        </div>   
    </div>
  )
}

export default UserNotifications