import React, { useEffect, useState } from 'react'
import axios from 'axios'
import { ToastContainer, toast } from 'react-toastify';
import { CgMail } from "react-icons/cg";
import { RiWhatsappFill } from "react-icons/ri";
import {useNavigate, Link} from 'react-router-dom'
import logoMain from '../images/Iconblack.svg'

const Contactus = () => {

    const [contactUsState, updatecontactUsState] = useState({
        email: "",
        message: "",
    });
    const [toastId, setToastId] = useState(null); // Add toastId state variable

    const [contactToastMsg, updatecontactToastMsg] = useState('')

    const phoneNumber = '+447342518045';

    const openChat = () => {
      // Construct the WhatsApp chat link with the phone number
      const chatLink = `https://api.whatsapp.com/send?phone=${encodeURIComponent(phoneNumber)}`;
  
      // Open the chat link in a new window or tab
      window.open(chatLink, '_blank');
    };


    const contactSubmit = async (e) =>{
        e.preventDefault();

        try {
            //send form data to backend
            const contactResp =  await axios.post('/api/contactus', {...contactUsState})
            const contactdata = contactResp.data;

            //checks if data contains any errors
            if(contactdata.error){
                updatecontactToastMsg('Failed to send, Please try again later.')
                setToastId(Date.now()); // Generate a new unique toastId 
            }else{
                updatecontactToastMsg(contactdata.successMsg)  
                setToastId(Date.now()); // Generate a new unique toastId
            }           
        } catch (error) {
            console.log(error)
        }

    }

    
    //Clear previous toast messages
    toast.dismiss(); 

    useEffect(()=>{
        if(contactToastMsg){
            if(contactToastMsg === 'Email sent successfully.'){
                toast.success(contactToastMsg, {
                    position:"top-center",
                    autoClose: 5000,
                    hideProgressBar:false,
                    newestOnTop:true,
                    closeOnClick:true,
                    rtl:false,
                    pauseOnFocusLoss:false,
                    draggable:false,
                    pauseOnHover: false,   
                })
            }else{
                toast.error(contactToastMsg, {
                    position:"top-center",
                    autoClose: 5000,
                    hideProgressBar:false,
                    newestOnTop:true,
                    closeOnClick:true,
                    rtl:false,
                    pauseOnFocusLoss:false,
                    draggable:false,
                    pauseOnHover: false,   
                })
            }
        }


    }, [contactToastMsg, toastId])


  return (
    //whole container
    <div className="relative overflow-hidden flex flex-col justify-center items-center bg-white text-[#000000d9] w-full min-h-screen ">
        {/* sign up */}
        <div className='relative w-full h-full py-[3rem] px-5 flex flex-col justify-center items-center space-y-[1rem] max-w-[500px]'>
            <Link to='/' className="relative flex justify-start items-center h-full w-[100px]">
                <img src= {logoMain} alt="" className='h-[50%] ' />
            </Link>
            <h1 className='text-[24px] text-center text-[#000000d9] font-[500] '>Contact Us</h1>
            <p className='text-[15.50px] text-center text-[#000000d9] font-[450]'>Need help? Contact us through our communication channels or fill the contact form below.</p>

            
                <div className='relative w-full flex flex-col justify-start items-center space-y-8 py-[2rem]'>
                    <form action="" method='' className='w-full flex flex-col justify-start items-center space-y-8 py-[2rem]' onSubmit={(e) => contactSubmit(e)}>

                        {/* all toast messages */}
                        <div className='relative w-full '>
                            <ToastContainer             
                                  className="relative w-full"
                            />
                        </div>

                        <div className='relative w-full flex flex-col justify-start items-start space-y-2 '>

                            <label htmlFor="" className='text-[15px] text-left text-[#000000d9] font-[450]'>Email</label>
                            <input type = 'email' name='email' className='bg-[#fff] border-[#d9d9d9] border-[1px] rounded-[6px] w-full h-[45px] text-black text-[16px] focus:outline-[0.50px] focus:outline-[#d4b657] px-[0.20rem] focus:transition-all hover:border-[#c3c3c3]' onChange={(e) => updatecontactUsState({...contactUsState, [e.target.name]: e.target.value}) }/>                    
                        </div>


                        <div className='relative w-full flex flex-col justify-start items-start space-y-2 '>
                            <label htmlFor="" className='text-[15px] text-left text-[#000000d9] font-[450]'>Message</label>
                            <textarea name="message" cols="10" rows="10" className="bg-[#fff] border-[#d9d9d9] border-[1px] rounded-[6px] w-full resize-none h-[150px] focus:outline-[#d4b657] px-[0.20rem] focus:transition-all hover:border-[#c3c3c3]" placeholder = "" onChange={(e)=> updatecontactUsState({...contactUsState, [e.target.name]: e.target.value})}></textarea>
                        </div>

                        <button type='submit' className='bg-[#1c1c1c] text-white border-[#1c1c1c] rounded-md flex justify-center items-center text-center w-full h-[40px] hover:cursor-pointer hover:bg-[#3c3c3c] hover:transition-all'>Send Message</button>
                    
                    </form>

                    <div className='relative max-w-[500px] min-h-[3rem] flex justify-center items-center space-x-[1rem]'>
                            <a href="#" className='relative w-[30px] h-[30px] md:w-[30px] md:h-[30px]' onClick={openChat}>
                                <RiWhatsappFill className='relative text-[#25D366] w-full h-full'/>
                            </a>

                            <a href="mailto:contact@thedirtyangels.com" className='relative w-[30px] h-[30px] md:w-[30px] md:h-[30px]'  >
                                <CgMail className='relative text-black w-full h-full'/>
                            </a>
                    </div>

                </div>
           
        </div>
    </div>
  )
}

export default Contactus