import React from 'react'
import { useState, useEffect } from 'react';
import axios from 'axios';
import jwt_decode from "jwt-decode";
import { Link } from 'react-router-dom';
import Viewport from '../components/Viewport';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useGlobalContext } from '../../assets/context';

import {GiConsoleController, GiHamburgerMenu} from "react-icons/gi"
import {RiNotification2Line} from "react-icons/ri"
import {AiFillHome} from "react-icons/ai" 
import {GrDocumentUpdate} from "react-icons/gr" 
import {AiFillDelete} from "react-icons/ai"  
import {TbBrandBooking} from "react-icons/tb"  
import {RiLuggageDepositLine} from "react-icons/ri"  
import {FaUsers} from "react-icons/fa"
import {BiLogOut} from "react-icons/bi" 
import { ImCancelCircle } from "react-icons/im";

import SideNav from '../components/SideNav';
import ReactDataTable from '../components/ReactDataTable';

const ModelBookings = () => {

  const [navActive, updateNavActive] = useState(null);
  const { AdminBookingData, fetchAdminData } = useGlobalContext();

  // Get the token from sessionStorage
  const adminToken = sessionStorage.getItem('adminInfo');

  useEffect(()=>{
      fetchAdminData()
  }, []);

  const hideNav = () =>{
  updateNavActive(false)
  }

  const clearSess = () =>{
  window.sessionStorage.clear();
  }

  //delete deposit on button click
  const deleteBooking = async (userId) =>{

    try {
        const response = await axios.post('/api/admin/deleteBooking', {userId},
        { 
            headers: {
                Authorization: `Bearer ${adminToken}`,
            },
        }) 

        const responseData = response.data.successMsg;

        toast.success(responseData, {
          position:"top-center",
          autoClose: 3000,
          hideProgressBar:false,
          newestOnTop:true,
          closeOnClick:true,
          rtl:false,
          pauseOnFocusLoss:false,
          draggable:false,
          pauseOnHover: false, 
        })

        setTimeout(() => {
          window.location.reload();
        }, 5000);

    } catch (error) {
        console.log(error)
    }
  }

  //delete deposit on button click
  const updateBookingStatus = async (userId) =>{

      try {
          const response = await axios.post('/api/admin/updateBookingStatus', {userId},
          { 
              headers: {
                  Authorization: `Bearer ${adminToken}`,
              },
          }) 
  
          const responseData = response.data.successMsg;
  
          toast.success(responseData, {
            position:"top-center",
            autoClose: 3000,
            hideProgressBar:false,
            newestOnTop:true,
            closeOnClick:true,
            rtl:false,
            pauseOnFocusLoss:false,
            draggable:false,
            pauseOnHover: false, 
          })
  
          setTimeout(() => {
            window.location.reload();
          }, 5000);
  
      } catch (error) {
          console.log(error)
      }
  }

  const AdminSideNav = [
      { url: '/admin/dashboard', name: 'Dashboard', icon: <AiFillHome className='cursor-pointer text-[22px] text-white transition-all ease-in duration-300 hover:scale-[1.1] md:text-[25px]' /> },
      { url: '/admin/modelupdate', name: 'Update Model', icon: <GrDocumentUpdate className='cursor-pointer text-[16px] bg-white text-white transition-all ease-in duration-300 hover:scale-[1.1] md:text-[20px]' /> },
      { url: '/admin/modeldelete', name: 'Delete Model', icon: <AiFillDelete className='cursor-pointer text-[22px] text-white transition-all ease-in duration-300 hover:scale-[1.1] md:text-[25px]' /> },
      { url: '/admin/modelbookings', name: 'Model Bookings', icon: <TbBrandBooking className='cursor-pointer text-[22px] text-white transition-all ease-in duration-300 hover:scale-[1.1] md:text-[25px]' /> },
      { url: '/admin/managedeposits', name: 'Manage Deposits', icon: <RiLuggageDepositLine className='cursor-pointer text-[22px] text-white transition-all ease-in duration-300 hover:scale-[1.1] md:text-[25px]' /> },
      { url: '/admin/manageusers', name: 'Manage Users', icon: <FaUsers className='cursor-pointer text-[22px] text-white transition-all ease-in duration-300 hover:scale-[1.1] md:text-[25px]' /> },
      { url: '/signin', name: 'Sign Out', icon: <BiLogOut className='cursor-pointer text-[22px] text-white transition-all ease-in duration-300 hover:scale-[1.1] md:text-[25px]' />, action: clearSess }
  ]

  const columns = [
    {
        name: 'Date',
        selector: (row) => row.date,
        cell: (row) => (
          <div style={{ whiteSpace: 'normal' }}>
            {new Date(row.date).toLocaleDateString('en-GB')}
          </div>
        )
    },
    {
        name: 'Email', 
        selector: row => row.bookingemail,
        cell: (row) =>(
            <div style={{whiteSpace:'normal'}}>
                {row.bookingemail}
            </div>
        )
    },
    {
          name: 'Amount', 
          selector: row => row.bookinamnt,
          cell: (row) =>(
              <div style={{whiteSpace:'normal'}}>
                  {row.bookinamnt}
              </div>
          )
    },
    {
        name: 'Model', 
        selector: row => row.bookingModel,
        cell: (row) =>(
            <div style={{whiteSpace:'normal'}}>
                {row.bookingModel}
            </div>
        )
    },
    {
      name: 'Status', 
      selector: row => row.bookstatus,
      cell: (row) =>(
          <div style={{whiteSpace:'normal'}}>
              {row.bookstatus}
          </div>
      )
    },
    {
        name: 'Action', 
        cell: (row) =>(
            <div style={{whiteSpace:'normal', display:'flex', justifyItems: 'center', alignItems: 'center'}}>
                <button onClick={()=> deleteBooking(row.bookingemail)} className="bg-red-700 text-white flex justify-center items-center min-w-[60px] h-[25px] border border-white rounded-md p-2">Delete</button>
                <button onClick={()=> updateBookingStatus(row.bookingemail)} className="bg-green-700 text-white flex justify-center items-center min-w-[60px] h-[25px] border border-white rounded-md p-2">Confirm</button>
            </div>
        )
    },
  ];
  return (
    <div className='relative flex flex-col overflow-x-hidden w-full min-h-screen bg-black text-white'>

        <SideNav hideNav = {hideNav} translateAction = {navActive ? `translate-x-[0px]` : `translate-x-[-500px]`} clearSess = {clearSess} backgroundColor = 'bg-black' arrowCoLor = 'text-white' iconBorderColor = 'border-white' iconBgColor = 'bg-black' textColor = 'text-white' links = {AdminSideNav}  />

        <div className='relative flex flex-col flex-grow w-full h-full py-2 px-4 overflow-hidden mb-[100px]'> 
            {/* <!-- ham and notification header --> */}
            <div className="relative w-full min-h-[100px] flex justify-center items-start">    
                <div className="relative w-full min-h-[50px] flex justify-between items-center">
                    {/* <!-- hamburger menu --> */}
                    <button onClick={() => updateNavActive(true) } className="relative">
                        <GiHamburgerMenu className='text-[20px] text-white' />
                    </button>   
                    
                    {/* <!-- manage notofication and balance --> */}
                    <div className='relative flex justify-end items-center space-x-4'>
                        
                        <Link to="/admin/notifications" className="relative flex justify-end items-center">
                            
                            <RiNotification2Line className='relative cursor-pointer text-white text-[18px]' />

                        </Link>
                    </div>
                </div>
            </div> 

            <div className='relative min-h-[500px] w-full flex flex-col justify-start items-center space-y-4 py-[2rem]'>
                <p className='relative w-full text-center text-white'>Manage Bookings</p>
                
                {/* all toast messages */}
                <div className='relative w-full '>
                    <ToastContainer          
                      className="relative w-full"
                    />
                </div>

                {/* table start */}
                <div className='relative max-w-full flex justify-center items-center '>
                    <ReactDataTable columns = {columns} data = {AdminBookingData} />   
                </div>
               

            </div>
        </div>

    </div>
  )
}

export default ModelBookings