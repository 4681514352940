import React from 'react'
import { useState, useEffect } from 'react';
import Viewport from './components/Viewport';
import Mobilenav from './components/Mobilenav';
import logoMain from '../images/iconword.svg'
import { RiLoginCircleFill } from "react-icons/ri";
import { AiOutlineMenu } from "react-icons/ai";
import {Link} from 'react-router-dom'

const Locations = () => {

    const { width } = Viewport();

    const[inactiveMobileNav, activeMobileNav] = useState(false);


    const breakpoint = 578;


  return (
    //whole container
    <div className="relative overflow-hidden flex flex-col justify-start items-center bg-mainBackColor text-white w-full min-h-screen ">
        {/* mobile menu */}
        {inactiveMobileNav && <Mobilenav closeMobileMenu = {() => activeMobileNav(false)}/>}

        {/* all sections */}
        {!inactiveMobileNav && <div className='absolute w-full h-full overflow-y-auto flex flex-col justify-start items-start '>
                <nav className="relative flex justify-between items-center min-h-[4rem] px-[1rem] md:px-[4rem] w-screen">

                    <Link to='/' className="relative flex justify-start items-center h-full w-[100px]">
                        <img src= {logoMain} alt="" className='h-[50%] ' />
                    </Link>

                    {width < breakpoint ? <button className="flex"  onClick={() => activeMobileNav(true)}>
                        <AiOutlineMenu className='text-white font-[500] text-[24px]'/>
                    </button> : ''}

            
                    <ul className="hidden sm:flex items-center justify-between space-x-[2rem]">
                    <li className="flex justify-center items-center text-white font-[500] text-[13.5px] tracking-wide transition-all uppercase hover:text-mainGoldColor"> <a href="/aboutus">About Us</a> </li>
                        <li className="flex justify-center items-center text-white font-[500] text-[13.5px] tracking-wide transition-all uppercase hover:text-mainGoldColor"> <a href="/howitworks">How it works</a> </li>
                        <li className="flex justify-center items-center text-white font-[500] text-[13.5px] tracking-wide transition-all uppercase hover:text-mainGoldColor"><a href="/locations" >Locations</a></li>
                    </ul>


                    <Link  to = "/signin" className="relative w-[100px] h-[33px] hidden justify-center items-center border bg-mainGoldColor border-mainGoldColor rounded space-x-1 sm:flex">
                        <p className="text-[14.5px] font-[500] tracking-wider text-white ">Login </p>
                        <RiLoginCircleFill className='text-white text-[17.5px] font-[500]  '/>
                    </Link> 
                </nav>

                {/* locations */}
                <div className='relative w-full flex flex-col justify-center items-center space-y-8 py-[6rem] sm:min-h-[45rem]'>
                    <div className='relative w-full grid-locations px-[1rem] md:px-[0]'>
                        <ul className=''>
                            <li className='text-[25px] text-[#9C9C9C] pb-[1rem]'>Australia</li>
                            <li className='text-[16px] text-white'>Melbourne</li>
                            <li className='text-[16px] text-white'>Geelong</li>
                            <li className='text-[16px] text-white'>Canberra</li>
                            <li className='text-[16px] text-white'>Perth</li>
                        </ul>  

                        <ul className=''>
                            <li className='text-[25px] text-[#9C9C9C] pb-[1rem]'>United Kingdom</li>
                            <li className='text-[16px] text-white'>Chelsea</li>
                            <li className='text-[16px] text-white'>Mayfair</li>
                            <li className='text-[16px] text-white'>Park Lane</li>
                            <li className='text-[16px] text-white'>Royal Oak</li>
                        </ul>  

                        <ul className=''>
                            <li className='text-[25px] text-[#9C9C9C] pb-[1rem]'>United States</li>
                            <li className='text-[16px] text-white'>California</li>
                            <li className='text-[16px] text-white'>New York</li>
                            <li className='text-[16px] text-white'>Texas</li>
                            <li className='text-[16px] text-white'>New Jersey</li>
                        </ul> 

                        <ul className=' '>
                            <li className='text-[25px] text-[#9C9C9C] pb-[1rem]'>UAE</li>
                            <li className='text-[16px] text-white'>Dubai</li>
                            <li className='text-[16px] text-white'>Sharjah</li>
                            <li className='text-[16px] text-white'>Abu Dhabi</li>
                        </ul> 

                        <ul className=''>
                            <li className='text-[25px] text-[#9C9C9C] pb-[1rem]'>South Africa</li>
                            <li className='text-[16px] text-white'>Durban</li>
                            <li className='text-[16px] text-white'>Cape Town</li>
                            <li className='text-[16px] text-white'>Pretoria</li>
                        </ul> 

                         
                    </div>                 
                </div>

                <footer className='relative w-full flex flex-col justify-center items-center space-y-10 px-[1rem] py-[3rem] border-t-mainGoldColor border-t-[2px]'>
                    <div className='relative w-full flex flex-col-reverse justify-center items-center space-y-[3rem] space-y-reverse sm:flex-row sm:justify-between sm:items-start sm:space-y-[0rem] sm:space-x-[4rem] sm:max-w-[400px] md:max-w-[700px]'>
                            <ul className='relative flex flex-col justify-center items-center space-y-8'>
                                <Link to='/' className="relative flex justify-start items-center h-full w-[100px]">
                                    <img src= {logoMain} alt="" className='h-[100%] ' />
                                </Link>
                                <li className='text-[#9C9C9C] text-[14px]'>© The Dirty Angels 2023</li>
                            </ul>

                    
                            <ul className='relative flex flex-col justify-center items-center space-y-8'>
                                <li className='text-[15px] text-[#9C9C9C]'><Link to="/aboutus">About Us</Link></li>
                                <li className='text-[15px] text-[#9C9C9C]'><Link to="/contactus">Contact Us</Link></li>
                                <li className='text-[15px] text-[#9C9C9C]'><Link to="/signup">Book An Escort</Link></li>
                            </ul>
                    </div>
                </footer>
        </div>} 
    </div>
  )
}

export default Locations