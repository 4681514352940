import React from 'react'
import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import jwt_decode from "jwt-decode";
import { Ring } from 'react-awesome-spinners'
import { useNavigate} from 'react-router-dom'
import {useGlobalContext} from '../../assets/context';
import logoMain from '../../images/iconword.svg'

import {GiHamburgerMenu} from "react-icons/gi"
import {RiNotification2Line} from "react-icons/ri"
import {AiFillHome} from "react-icons/ai" 
import {BiHistory} from "react-icons/bi" 
import {CiRollingSuitcase} from "react-icons/ci"  
import {BiMessageAlt} from "react-icons/bi"  
import {BiLogOut} from "react-icons/bi" 
import {AiOutlinePlus} from "react-icons/ai"
import { AiOutlineSearch } from "react-icons/ai";


import SideNav from '../components/SideNav'

const UserBookModel = () => {

    const navigate = useNavigate();

    const{fetchUserData, allUserMongoData, userNotifDataAll, setSelectedImage} = useGlobalContext();

    const [searchQuery, setSearchQuery] = useState('');
    const [filteredImages, setFilteredImages] = useState([]);
    const [modelNotFound, setModelNotFound] = useState(false);
    const [allimages, allimagesUpdate] = useState([]);
    const [isLoading, updateisLoading] = useState(true)

    const [navActive, updateNavActive] = useState(null)

    const [decodedData, updatedecodedData] = useState({});

    const userToken = sessionStorage.getItem('userInfo');
    const cachedAllImages = sessionStorage.getItem('cachedImagesBookModel');
  
    useEffect(() => {
      fetchUserData();
  
      if (userToken) {
        const decoded = jwt_decode(userToken);
        updatedecodedData(decoded.id);
      }
  
      if (cachedAllImages) {
        allimagesUpdate(JSON.parse(cachedAllImages));
        updateisLoading(false); 
      }
  
    }, []);
  
    useEffect(() => {
      const getImages = () => {
        axios
          .get('/api/images')
          .then((response) => {
            allimagesUpdate(response.data.images);
            sessionStorage.setItem('cachedImagesBookModel', JSON.stringify(response.data.images));
            updateisLoading(false); // Data loading complete, set isLoading to false
          })
          .catch((error) => {
            console.log(error);
          });
      };
  
      if (!cachedAllImages) {
        getImages();
      }
  
    }, [cachedAllImages]);


    // function to display model info on model click
    const displayModelInfo = (ModelName)=>{
        setSelectedImage(ModelName);
        navigate('/user/Modelinfo');
        
    }

    //function to filter images in image search
    const filterImages = () => {
        const filtered = allimages.filter((image) =>
        image.ModelName.toLowerCase().includes(searchQuery.toLowerCase())
        );
        setFilteredImages(filtered);
        setModelNotFound(filtered.length === 0);
    };

    const hideNav = () =>{
    updateNavActive(false)
    }

    const clearSess = () =>{
    window.sessionStorage.clear();
    }

    const userDashLinks = [
        { url: '/user/dashboard', name: 'Dashboard', icon: <AiFillHome className='cursor-pointer text-[22px] text-[#d4b657] transition-all ease-in duration-300 hover:scale-[1.1] md:text-[25px]' /> },
        { url: '/user/bookmodel', name: 'Book Model', icon: <CiRollingSuitcase className='cursor-pointer text-[22px]  text-[#d4b657] transition-all ease-in duration-300 hover:scale-[1.1] md:text-[20px]' /> },
        { url: '/user/bookedmodels', name: 'Booked Models', icon: <BiHistory className='cursor-pointer text-[22px] text-[#d4b657] transition-all ease-in duration-300 hover:scale-[1.1] md:text-[25px]' /> },
        { url: '/user/Contactus', name: 'Contact Us', icon: <BiMessageAlt className='cursor-pointer text-[22px] text-[#d4b657] transition-all ease-in duration-300 hover:scale-[1.1] md:text-[25px]' /> },
        { url: '/signin', name: 'Sign Out', icon: <BiLogOut className='cursor-pointer text-[22px] text-[#d4b657] transition-all ease-in duration-300 hover:scale-[1.1] md:text-[25px]'/>, action: clearSess },
    ];





  if(isLoading){
        return (
            <div className="relative overflow-hidden flex flex-col justify-center items-center bg-mainBackColor text-white w-full min-h-screen ">
                <Ring color = '#d4b657'/>
            </div>
        )
  }else{
        return (
            <div className='relative flex flex-col overflow-x-hidden w-full min-h-screen bg-black text-white'>

                <SideNav hideNav = {hideNav} translateAction = {navActive ? `translate-x-[0px]` : `translate-x-[-500px]`} clearSess = {clearSess} userName={`Brad.`} backgroundColor = 'bacground-linearGradient' arrowCoLor = 'text-white' iconBorderColor = 'border-white' iconBgColor = 'bg-inherit' textColor = 'text-white'  links = {userDashLinks} SideNavuserName = {decodedData.UserName} />

                <div className='relative flex flex-col flex-grow w-full h-full py-2 px-4 overflow-hidden mb-[100px]'> 
                    {/* <!-- ham and notification header --> */}
                    <div className="relative w-full min-h-[100px] flex justify-center items-start">    
                        <div className="relative w-full min-h-[50px] flex justify-between items-center">
                            {/* <!-- hamburger menu --> */}
                            <button onClick={() => updateNavActive(true) } className="relative">
                                <GiHamburgerMenu className='text-[20px] text-white' />
                            </button>   
                            
                            {/* <!-- manage notofication and balance --> */}
                            <div className='relative flex justify-end items-center space-x-4'>
                                
                                <Link to="/user/deposit" className="relative flex justify-end items-center">
                                    <div className="relative flex justify-center items-center" >
                                        <p className="relative text-[14px] text-white hover:text-mainGoldColor">$ {allUserMongoData.balance} </p>
                                    </div>
                                </Link>                  
                                
                                <Link to="/user/notifications" className="relative flex justify-end items-center">
                                    
                                    <RiNotification2Line className='relative cursor-pointer text-white text-[18px]' />

                                    {userNotifDataAll.length > 0 && <div className="flex absolute left-[8px] top-[-4px] w-[12px] h-[12px] bg-red-600 border-red-600 rounded-full justify-center items-center" >
                                        <p className="relative text-[8px] text-white"> {userNotifDataAll.length} </p>
                                    </div>}
                                </Link>

                            </div>
                        </div>
                    </div> 


                    {/* search bar and image grid */}
                    <div className='relative w-full min-h-[20rem] py-[4rem] flex flex-col justify-start items-center space-y-[2rem] px-[1rem] md:px-[3rem]'>
                        {/* model search bar */}
                        <div className='relative w-full min-h-[5rem] flex justify-end items-center'>
                            <div className='relative max-w-[800px] flex justify-start items-start '>
                                <input value={searchQuery} onChange={(e) => setSearchQuery(e.target.value)} type="text" className='relative bg-inherit border-[#d4b657] border-[1px] rounded-[6px] w-full h-[40px] px-[0.50rem] text-white text-[16px] md:w-[300px] focus:outline-1 focus:outline focus:transition-all hover:border-[#c3c3c3]' placeholder='Search by name' />
                                    
                                    <div className='absolute right-0 h-full flex justify-end items-center'>
                                        <button onClick={() => filterImages()} className='relative h-full w-[50px] flex justify-center items-center'>
                                            <AiOutlineSearch className='text-white'/>
                                        </button>  
                                    </div>
                            </div>
                        </div>


                        {/* all escorts grid */}
                        <div className='relative w-full min-h-[35rem] flex flex-col justify-start items-center space-y-[2rem]'>
                            <h2 className='text-[23px] text-center text-white font-[500] capitalize'>All Starter Account Escorts</h2>
                                <div className='relative w-full min-h-[20rem] grid-container'>
                                    {modelNotFound ? '' : (filteredImages.length > 0 ? filteredImages : allimages).map(
                                        (image, index) => (
                                        <button onClick={() => displayModelInfo(image.ModelName)} key={`${image.ModelName}-${index}`} className="relative flex flex-col justify-start items-start space-y-[0.10rem] rounded-md p-4 hover:bg-[#0b0b0b] hover:border-[#0b0b0b] hover:transition-all">
                                            <img src={image.url} alt="" className="picture-size border border-black rounded-sm" />
                                            <h6 className="text-[14.50px] text-white font-[500] pt-[0.50rem]">{image.ModelName}</h6>
                                            <h6 className="text-[12.50px] text-[#bbbbbb] font-[550]">{image.Location}</h6>
                                            <h6 className="text-[14px] text-mainGoldColor font-[550]">{image.AverageFee}</h6>
                                        </button>
                                        )
                                    )}
                                </div>    
                            

                            {modelNotFound && <div className='relative w-full flex flex-col justify-center items-center space-y-2'>
                                <p className=" text-[16px] text-mainGoldColor font-[550]">Model not found.</p>
                                <button onClick={()=>window.location.reload()}  className='relative text-[14px] min-w-[130px] min-h-[28px] flex justify-center items-center border bg-mainGoldColor border-mainGoldColor rounded space-x-1 p-[0.50rem]'>Go Back</button>
                            </div>}
        
                        </div>

                        {/* Need more escorts? */}
                        <div className='w-full min-h-[5rem] flex flex-col justify-start items-center space-y-2 px-[1rem]'>
                            <h1 className='text-[23px] text-center text-white font-[500] capitalize'>Need more escorts <span className='text-mainGoldColor'>?</span></h1>
                            <p className='text-[17px] text-center text-white font-[350]'>Upgrade your account to access thousands of Dirty Angels with VIP account benefits.</p>

                            <div className='relative w-[90%] min-h-[5rem] flex justify-center items-center space-x-[1rem] ssm:w-[150px]'>
                                <Link to= '/user/deposit' className='bg-[#1c1c1c] text-white border-[#1c1c1c] rounded-md flex justify-center items-center space-x-1 text-center w-full h-[40px] hover:cursor-pointer hover:bg-[#2a2a2a] transition-all'>
                                    <p>Upgrade</p>
                                    <AiOutlinePlus className=' text-[13px] text-[#d4b657]' />
                                </Link>
                            </div>
                        </div>
                    </div>

                </div>

                <footer className="relative flex-shrink-0 w-full h-[6rem] flex justify-center items-end py-[1rem]">
                    <img src={logoMain} alt="" className="h-[50%] w-[30%] ssm:w-[120px] xl:w-[130px]" />
                </footer>
            </div>
        )
    }
}

export default UserBookModel