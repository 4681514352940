import React from 'react'
import { useState, useEffect } from 'react';
import Viewport from './components/Viewport';
import Mobilenav from './components/Mobilenav';
import {useNavigate, Link} from 'react-router-dom'
import logoMain from '../images/iconword.svg'
import { RiLoginCircleFill } from "react-icons/ri";
import { AiOutlineMenu } from "react-icons/ai";

const HowitWorks = () => {

    const { width } = Viewport();

    const[inactiveMobileNav, activeMobileNav] = useState(false);


    const breakpoint = 578;

  return (
    //whole container
    <div className="relative overflow-hidden flex flex-col justify-start items-center bg-mainBackColor text-white w-full min-h-screen ">
        {/* mobile menu */}
        {inactiveMobileNav && <Mobilenav closeMobileMenu = {() => activeMobileNav(false)}/>}

        {/* all sections */}
        {!inactiveMobileNav && <div className='absolute w-full h-full overflow-y-auto flex flex-col justify-start items-start '>
                <nav className="relative flex justify-between items-center min-h-[6rem] p-[1rem] md:px-[4rem] w-screen">

                    <Link to='/' className="relative flex justify-start items-center h-full w-[100px]">
                        <img src= {logoMain} alt="" className='h-[100%] ' />
                    </Link>

                    {width < breakpoint ? <button className="flex"  onClick={() => activeMobileNav(true)}>
                        <AiOutlineMenu className='text-white font-[500] text-[24px]'/>
                    </button> : ''}

            
                    <ul className="hidden sm:flex items-center justify-between space-x-[2rem]">
                    <li className="flex justify-center items-center text-white font-[500] text-[13.5px] tracking-wide transition-all uppercase hover:text-mainGoldColor"> <a href="/aboutus">About Us</a> </li>
                            <li className="flex justify-center items-center text-white font-[500] text-[13.5px] tracking-wide transition-all uppercase hover:text-mainGoldColor"> <a href="/howitworks">How it works</a> </li>
                            <li className="flex justify-center items-center text-white font-[500] text-[13.5px] tracking-wide transition-all uppercase hover:text-mainGoldColor"><a href="/locations" >Locations</a></li>
                    </ul>
       

                    <Link  to = "/signin" className="relative w-[100px] h-[33px] hidden justify-center items-center border bg-mainGoldColor border-mainGoldColor rounded space-x-1 sm:flex">
                        <p className="text-[14.5px] font-[500] tracking-wider text-white ">Login </p>
                        <RiLoginCircleFill className='text-white text-[17.5px] font-[500]  '/>
                    </Link> 
                </nav>

                {/* how it works */}
                <div className='relative w-full flex flex-col justify-center items-center space-y-8 py-[6rem] sm:min-h-[45rem]'>
                    <div className='relative w-full px-[1rem] flex flex-col justify-start items-start space-y-4 md:max-w-[700px]'>
                        <h3 className='text-[22px] text-left text-mainGoldColor font-[500] uppercase md:max-w-[1000px]'>How to book an escort</h3>

                        <ul className='relative flex flex-col justify-start items-start space-y-4 list-disc list-inside'>
                            <li className='text-[15px] text-white'>Navigate to the <Link to="/signup" className='text-mainGoldColor'>Sign Up page</Link> and register with your refferal code.</li>
                            <li className='text-[15px] text-white'>View selected escort for more details.</li>
                            <li className='text-[15px] text-white'>Use any of the highlighted booking links to book escort or contact our Support if you require help with booking.</li>
                            <li className='text-[15px] text-white'>Enjoy a wonderful time with our Dirty Angels.</li>
                        </ul>
                    </div>                 
                </div>

                <footer className='relative w-full flex flex-col justify-center items-center space-y-10 px-[1rem] py-[3rem] border-t-mainGoldColor border-t-[2px]'>
                    <div className='relative w-full flex flex-col-reverse justify-center items-center space-y-[3rem] space-y-reverse sm:flex-row sm:justify-between sm:items-start sm:space-y-[0rem] sm:space-x-[4rem] sm:max-w-[400px] md:max-w-[700px]'>
                            <ul className='relative flex flex-col justify-center items-center space-y-8'>
                                <Link to='/' className="relative flex justify-start items-center h-full w-[100px]">
                                    <img src= {logoMain} alt="" className='h-[100%] ' />
                                </Link>
                                <li className='text-[#9C9C9C] text-[14px]'>© The Dirty Angels 2023</li>
                            </ul>

                    
                            <ul className='relative flex flex-col justify-center items-center space-y-8'>
                                <li className='text-[15px] text-[#9C9C9C]'><Link to="/aboutus">About Us</Link></li>
                                <li className='text-[15px] text-[#9C9C9C]'><Link to="/contactus">Contact Us</Link></li>
                                <li className='text-[15px] text-[#9C9C9C]'><Link to="/signup">Book An Escort</Link></li>
                            </ul>
                    </div>
                </footer>
        </div>} 
    </div>
  )
}

export default HowitWorks