import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import { BrowserRouter } from 'react-router-dom';
import './assets/tailwind.css';
import { AppProvider } from './assets/context';





const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  // <React.StrictMode>
    <BrowserRouter>
      <AppProvider>
          
          <App />
      </AppProvider>
    </BrowserRouter>
  // </React.StrictMode>
);

