import React from 'react'
import { AiOutlineClose } from "react-icons/ai";
import { RiLoginCircleFill } from "react-icons/ri";
import logoMain from '../../images/iconword.svg'
import { Link} from 'react-router-dom'

const Mobilenav = ({closeMobileMenu}) => {


  return (

    <div className='absolute w-full h-full flex flex-col items-start justify-start space-y-[8rem] bg-black px-5'>

        <div className='relative flex justify-between items-center min-h-[4rem] w-full'>
            <Link to='/' className="relative flex justify-start items-center h-full w-[100px]">
                <img src= {logoMain} alt="" className='h-[50%] ' />
            </Link>

            <button onClick={() => closeMobileMenu()}>
                <AiOutlineClose className='text-white font-[500] text-[23px]' />
            </button>  
        </div>

        <ul className="relative w-full flex flex-col justify-start items-center space-y-[8rem]">
            <li className="flex justify-center items-center text-white font-[500] text-[13.5px] tracking-wide transition-all uppercase hover:text-mainGoldColor"> <Link to="/aboutus">About Us</Link> </li>
            <li className="flex justify-center items-center text-white font-[500] text-[13.5px] tracking-wide transition-all uppercase hover:text-mainGoldColor"> <Link to="/howitworks">How it works</Link> </li>
            <li className="flex justify-center items-center text-white font-[500] text-[13.5px] tracking-wide transition-all uppercase hover:text-mainGoldColor"><Link to="/locations" >Locations</Link></li>
            <Link  to = "/signin" className="relative w-[100px] h-[33px] justify-center items-center border bg-mainGoldColor border-mainGoldColor rounded space-x-1 flex">
                <p className="text-[14.5px] font-[500] tracking-wider text-white ">Login </p>
                <RiLoginCircleFill className='text-white text-[17.5px] font-[500]  '/>
            </Link> 
        </ul>
  

    </div>
  )
}

export default Mobilenav