import React from 'react'
import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import jwt_decode from "jwt-decode";
import { useGlobalContext } from '../../assets/context';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import logoMain from '../../images/iconword.svg'


import { ImCancelCircle } from "react-icons/im";
import {GiHamburgerMenu} from "react-icons/gi"
import {RiNotification2Line} from "react-icons/ri"
import {AiFillHome} from "react-icons/ai" 
import {BiHistory} from "react-icons/bi" 
import {CiRollingSuitcase} from "react-icons/ci"  
import {BiMessageAlt} from "react-icons/bi"  
import {BiLogOut} from "react-icons/bi" 
import {BsPaypal} from "react-icons/bs"
import {AiOutlineDownload} from "react-icons/ai" 
import {FaBitcoin} from "react-icons/fa" 

import SideNav from '../components/SideNav';

const UserDeposit = () => {

    const [navActive, updateNavActive] = useState(null);

    const [selectPayMethodActive, updateselectPayMethodActive] = useState(null);

    const [payPalActive, updatepayPalActive] = useState(null);

    const [cryptoActive, updateCryptoActive] = useState(null);

    const [activeCryptoClip, updateActiveCryptoClip] = useState(null);

    const{fetchUserData, allUserMongoData, userNotifDataAll} = useGlobalContext();
    const [decodedData, updatedecodedData] = useState({});

    const userToken = sessionStorage.getItem('userInfo');

    useState(()=>{
        fetchUserData()

        if(userToken){
            const decoded = jwt_decode(userToken);
            updatedecodedData(decoded.id)
        }
        
    }, [])

    const handleEthCopy = () =>{
        updateActiveCryptoClip('ETHACTIVE')
    }

    const handleBtcCopy = () =>{
        updateActiveCryptoClip('BTCACTIVE')
    }

    const hideNav = () =>{
        updateNavActive(false);
    }

    const clearSess = () =>{
        window.sessionStorage.clear();
    }

    const userDashLinks = [
        { url: '/user/dashboard', name: 'Dashboard', icon: <AiFillHome className='cursor-pointer text-[22px] text-[#d4b657] transition-all ease-in duration-300 hover:scale-[1.1] md:text-[25px]' /> },
        { url: '/user/bookmodel', name: 'Book Model', icon: <CiRollingSuitcase className='cursor-pointer text-[22px]  text-[#d4b657] transition-all ease-in duration-300 hover:scale-[1.1] md:text-[20px]' /> },
        { url: '/user/bookedmodels', name: 'Booked Models', icon: <BiHistory className='cursor-pointer text-[22px] text-[#d4b657] transition-all ease-in duration-300 hover:scale-[1.1] md:text-[25px]' /> },
        { url: '/user/Contactus', name: 'Contact Us', icon: <BiMessageAlt className='cursor-pointer text-[22px] text-[#d4b657] transition-all ease-in duration-300 hover:scale-[1.1] md:text-[25px]' /> },
        { url: '/signin', name: 'Sign Out', icon: <BiLogOut className='cursor-pointer text-[22px] text-[#d4b657] transition-all ease-in duration-300 hover:scale-[1.1] md:text-[25px]'/>, action: clearSess },
    ];

  return (
    <div className='relative flex flex-col overflow-x-hidden w-full min-h-screen bg-black text-white'>
        
        {/* select payment method */}
        {selectPayMethodActive && <div className='absolute w-full min-h-[100%] top-0 left-0 flex justify-center items-center bg-[#111111c9] z-50'>
            <div className='relative min-h-[500px] w-[90%] flex flex-col justify-start items-center space-y-4 bg-[#d6d6d6] p-4 border border-[#d6d6d6] rounded-md sm:w-[400px] ssm:w-[80%]'>
                  
                <div className='relative w-full flex justify-end items-center'>
                    <button onClick={()=> updateselectPayMethodActive(false)} className="relative "  >
                        <ImCancelCircle className='cursor-pointer text-[22px] text-black transition-all ease-in duration-300 hover:scale-[1.1] md:text-[25px]' />
                    </button>
                </div>
                  
                  
                <h3 className='relative w-full text-center text-black text-[17px] font-[550] pt-[1rem]'>Choose Payment Method</h3>

                <div className='relative w-full flex flex-col justify-start items-center space-y-[1rem] pt-[1rem]'>
                    
                    <div className='relative flex flex-col justify-start items-center space-y-2 px-2 pt-2 pb-4 w-[90%] bg-[#111111] border border-black rounded-md min-h-[140px] ssm:w-[85%] sm:w-[250px]'>
                            <h5 className='relative text-[#002D62] text-[20px] font-[650]'>Paypal</h5>
                            <p className='relative text-white text-[15px] text-center font-[450]'>Deposit to your Dirty Angels account using Paypal.</p>
                            <button onClick={()=>{ updatepayPalActive(true); updateselectPayMethodActive(false)}} className='bg-[#111111] text-black border border-white rounded-md flex justify-center items-center space-x-2 text-center w-[120px] h-[35px] hover:cursor-pointer hover:bg-[#2a2a2a] transition-all'>
                                <p className='text-[16px] text-white'>Select</p>
                                < BsPaypal className='text-[19px] text-[#002D62]'/>
                            </button>
                    </div>

                    <div className='relative flex flex-col justify-start items-center space-y-2 px-2 pt-2 pb-4 w-[90%] bg-[#111111] border border-black rounded-md min-h-[140px] ssm:w-[85%] sm:w-[250px]'>
                            <h5 className='relative text-[#FEBE10] text-[20px] font-[600]'>Cryptocurrency</h5>
                            <p className='relative text-white text-[15px] text-center font-[450]'>Deposit to your Dirty Angels account using Cryptocurrency.</p>
                            <button onClick={()=> {updateCryptoActive(true); updateselectPayMethodActive(false)}} className='bg-[#111111] text-black border border-white rounded-md flex justify-center items-center space-x-2 text-center w-[120px] h-[35px] hover:cursor-pointer hover:bg-[#2a2a2a] transition-all'>
                                <p className='text-[16px] text-white'>Select</p>
                                < FaBitcoin className='text-[19px] text-[#FEBE10]'/>
                            </button>
                    </div>

                </div>
            </div>
        </div>}

        {/* paypal display message */}
        {payPalActive && <div className='absolute w-full min-h-[100%] top-0 left-0 flex justify-center items-center bg-[#111111c9] z-50'>
            <div className='relative min-h-[100px] w-[90%] flex flex-col justify-start items-center space-y-4 bg-white p-4 border border-[#d6d6d6] rounded-md sm:w-[400px] ssm:w-[80%]'>
                
                <div className='relative w-full flex justify-end items-center'>
                    <button onClick={()=> updatepayPalActive(false)} className="relative "  >
                        <ImCancelCircle className='cursor-pointer text-[22px] text-[black] transition-all ease-in duration-300 hover:scale-[1.1] md:text-[25px]' />
                    </button>
                </div>

                <div className='relative w-full '>
                    < BsPaypal className='text-[19px] text-[#002D62]'/>
                    <h6 className='text-black text-center'>Payment method temporarily unavailable. Please try again later or select another deposit method.</h6>
                </div>

                <div className='relative w-full flex justify-center items-center'>
                    <button onClick={()=> {updateCryptoActive(true); updatepayPalActive(false);}} className='bg-[#111111] px-2 text-black border border-white rounded-md flex justify-center items-center space-x-2 text-center min-w-[120px] h-[42px] hover:cursor-pointer hover:bg-[#2a2a2a] transition-all'>
                        <p className='text-[16px] text-white'>Change Method</p>
                    </button>
                </div>
            </div>
        </div>}

        {/* crypto display message */}
        {cryptoActive && <div className=' absolute w-full min-h-[100%] py-4 top-0 left-0 flex justify-center items-center bg-[#111111c9] z-50'>
            <div className='relative min-h-[500px] w-[90%] flex flex-col justify-start items-center space-y-4 bg-white p-4 border border-[#d6d6d6] rounded-md sm:w-[400px] ssm:w-[80%]'>
                
                <div className='relative w-full flex justify-end items-center'>
                    <button onClick={()=> updateCryptoActive(false)} className="relative "  >
                        <ImCancelCircle className='cursor-pointer text-[22px] text-[black] transition-all ease-in duration-300 hover:scale-[1.1] md:text-[25px]' />
                    </button>
                </div>

                <h6 className="relative text-center  text-[18px] text-black">Make Crptocurrency Deposit</h6>

                {/* <!-- warning message --> */}
                <div className="relative w-full min-h-[100px] flex justify-start items-start px-3 py-3 bg-[#f5f6fa] border border-[#f5f6fa] rounded-xl">
                    <p className="relative text-left text-[15px] text-[#0066b2]">Please make your payment to any of the crypto wallet addresses below. Always verify and confirm you copied the wallet address correctly.</p>
                </div>

                {/* <!-- btc address --> */}
                <div className="relative w-full min-h-[100px] flex flex-col justify-start items-start space-y-2 px-3 py-4 bg-white border border-[#e3e3e3] rounded-xl">
                    <p className="w-full text-left  text-[15px] font-[600] text-black">Bitcoin</p>
                    <p className="w-full text-left  text-[17px] font-[550] text-black">BTC address:</p>
                    <p className="w-full text-left  text-[16px] font-[500] text-[#FEBE10] break-words pb-2">bc1qxt3g54xlhw5jd3uq259zv8qzf7gj407vqwdmtp</p>

                    {/* <!-- alert box on copy --> */}
                    {activeCryptoClip === 'BTCACTIVE' && <div className="relative w-full justify-end items-center">
                        <div className=" relative max-w-[160px] min-h-[20px] bg-[#555] p-[0.5rem] text-white border border-[#555] rounded-sm mb-5 after:content-[''] after:w-[1px] after:h-[1px] after:absolute after:left-[45%] after:mt-[9px] after:bg-white after:border-x-[10px] after:border-x-white  after:border-t-[10px] after:border-t-[#555]">
                            <p className="w-full text-[15px] text-center">Copied to clipboard</p>
                        </div>
                    </div>}

                    <CopyToClipboard onCopy={handleBtcCopy} text={'bc1qxt3g54xlhw5jd3uq259zv8qzf7gj407vqwdmtp'}>
                        <button className="w-full font-[550] text-[15px] text-black min-h-[40px]  border border- bg-[#f5f6fa] rounded-md transition-all ease-in duration-300 hover:bg-[#EDEDF5]">Copy</button>
                    </CopyToClipboard>
                    
                </div>

                {/* <!-- Eth address --> */}
                <div className="relative w-full min-h-[100px] flex flex-col justify-start items-start space-y-2 px-3 py-4 bg-white border border-[#e3e3e3] rounded-xl">
                    <p className="w-full text-left text-[15px] font-[600] text-black">Ethereum</p>
                    <p className="w-full text-left text-[17px] font-[550] text-black">ETH address:</p>
                    <p className="w-full text-left text-[16px] font-[500] text-[#00573F] break-words pb-2" id="ethaddress">0x0D3753eC6c2eEaA5911793534FC4886fa72441Ab</p>
                    
                    {/* <!-- alert box on copy --> */}
                    {activeCryptoClip === 'ETHACTIVE' && <div className="relative w-full justify-end items-center">
                        <div className=" relative max-w-[160px] min-h-[20px] bg-[#555] p-[0.5rem] text-white border border-[#555] rounded-sm mb-5 after:content-[''] after:w-[1px] after:h-[1px] after:absolute after:left-[45%] after:mt-[9px] after:bg-white after:border-x-[10px] after:border-x-white  after:border-t-[10px] after:border-t-[#555]">
                            <p className="w-full text-[15px] text-center">Copied to clipboard</p>
                        </div>
                    </div>}

                    <CopyToClipboard onCopy={handleEthCopy} text={'0x0D3753eC6c2eEaA5911793534FC4886fa72441Ab'}>
                        <button className="w-full text-[15px] font-[500] text-black min-h-[40px] border bg-[#f5f6fa] rounded-md transition-all ease-in duration-300 hover:bg-[#EDEDF5]" id="ethaddressbtn">Copy</button>
                    </CopyToClipboard>
                </div>

                <div>
                    <p className='text-black text-[14px]'>Need help? Contact <a className='text-[#0066b2]' href="mailto:support@thedirtyangels.com">Support.</a></p>
                </div>

            </div>
        </div>}

        <SideNav hideNav = {hideNav} translateAction = {navActive ? `translate-x-[0px]` : `translate-x-[-500px]`} clearSess = {clearSess} userName={`Brad.`} backgroundColor = 'bacground-linearGradient' arrowCoLor = 'text-white' iconBorderColor = 'border-white' iconBgColor = 'bg-inherit' textColor = 'text-white'  links = {userDashLinks} SideNavuserName = {decodedData.UserName}/>

        <div className='relative flex flex-col flex-grow w-full h-full py-2 px-4 overflow-hidden mb-[100px]'> 
            {/* <!-- ham and notification header --> */}
            <div className="relative w-full min-h-[100px] flex justify-center items-start">    
                <div className="relative w-full min-h-[50px] flex justify-between items-center">
                    {/* <!-- hamburger menu --> */}
                    <button onClick={() => updateNavActive(true) } className="relative">
                        <GiHamburgerMenu className='text-[20px] text-white' />
                    </button>   
                    
                    {/* <!-- manage notofication and balance --> */}
                    <div className='relative flex justify-end items-center space-x-4'>                
                        
                        <Link to="/user/notifications" className="relative flex justify-end items-center">
                            
                            <RiNotification2Line className='relative cursor-pointer text-white text-[18px]' />

                            {userNotifDataAll.length > 0 && <div className="flex absolute left-[8px] top-[-4px] w-[12px] h-[12px] bg-red-600 border-red-600 rounded-full justify-center items-center" >
                                <p className="relative text-[8px] text-white"> {userNotifDataAll.length} </p>
                            </div>}
                        </Link>
                    </div>
                </div>
            </div> 

            {/* section */}
            <div className='relative min-h-[500px] w-full flex flex-col justify-start items-center space-y-4 py-[2rem]'>
             
                <div className='w-full flex justify-end items-center'>
                    <button onClick={()=> updateselectPayMethodActive(true)} className='bg-[#0d0d0d] text-white border-[#1c1c1c] rounded-md flex justify-center items-center space-x-2 text-center w-[120px] h-[40px] hover:cursor-pointer hover:bg-[#2a2a2a] transition-all'>
                        <p>Deposit</p>
                        <AiOutlineDownload className=' text-[13px] text-white' />
                    </button>
                </div>

                <div className='relative w-[100%] min-h-[100px] bg-black BalanceBoxshadow rounded-lg flex flex-col justify-center items-center space-y-4 px-5 py-5 sm:w-[500px] xl:w-[600px]'>
                    <div className='w-full relative flex flex-col space-y-2 justify-center items-center'>
                        <h3 className="font-[550] text-[19.50px] text-white text-center md:text-[23px]">Balance</h3>
                        <h3 className="font-[550] text-[19.50px] text-white text-center md:text-[23px]">${allUserMongoData.balance}</h3>
                    </div>
                </div>

            </div>           


        </div>
        <footer className="relative flex-shrink-0 w-full h-[6rem] flex justify-center items-end py-[1rem]">
            <img src={logoMain} alt="" className="h-[50%] w-[30%] ssm:w-[120px] xl:w-[130px]" />
        </footer>

    </div>
  )
}

export default UserDeposit