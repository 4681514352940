import React, { useContext } from 'react';
import axios from 'axios';
import { useState, useEffect  } from 'react'

const AppContext = React.createContext();

const AppProvider = ({ children }) => {
    const [successMessagesDefault, successMessageUpdate] = useState('');

    const [selectedImage, setSelectedImage] = useState('')

    const [isAdminAuthenticated, isAdminAuthenticatedUpdate] = useState(false)
    const [isUserAuthenticated, isUserAuthenticatedUpdate] = useState (false)

    //admin
    const [allUsersCloudinaryData, UpdateallUsersCloudinaryData] = useState([])
    const [allUsersMongoData, updateallUsersMongoData] = useState([])
    const [MongoDepositData, updateMongoDepositData] = useState([])
    const [AdminNotifData, updateAdminNotifData] = useState([])
    const [AdminBookingData, updateAdminBookingData] = useState([])


    //users
    const [allUserMongoData, updateallUserMongoData] = useState([])
    const [userNotifDataAll, updateuserNotifDataAll] = useState([])
    const [userBookingHistory, updateuserBookingHistory] = useState([])
    

    const fetchAdminData = async () =>{

      try {
        // Get the token from sessionStorage
        const adminToken = sessionStorage.getItem('adminInfo');

        // Include the token in the request headers
        const adminConfig = {
          headers: {
            Authorization: `Bearer ${adminToken}`,
          },
        };

        // Fetch all mongodb data for admin
        const adminResponse = await axios.get('/api/admin/dashboard', adminConfig);
        const adminData = adminResponse.data.user;

        updateallUsersMongoData(adminData)

        //fetch all deposit data
        const depDataAll = await axios.get(`/api/admin/managedeposits`, adminConfig)
        const depDataAllResp = depDataAll.data.user;

        updateMongoDepositData(depDataAllResp)

        //fetch all notification data
        const NotifFromServer = await axios.get(`/api/admin/managenotifications`, adminConfig)
        const NotifFromServerResp = NotifFromServer.data.user;

        updateAdminNotifData(NotifFromServerResp)

        //fetch all booking data
        const BookingFromServer = await axios.get(`/api/admin/managebookings`, adminConfig)
        const BookingFromServerResp = BookingFromServer.data.user;

        updateAdminBookingData(BookingFromServerResp)

        
        //fetch all clodinary data
        const cloudinaryResp = await axios.get('/api/images')
        const cloudinaryRespData = cloudinaryResp.data.images
        
        UpdateallUsersCloudinaryData(cloudinaryRespData)        

      } catch (error) {
        console.log(error)
      }
    }

    const fetchUserData = async () => {
        try {
            const userToken = sessionStorage.getItem('userInfo');

            const userConfig = {
              headers: {
                Authorization: `Bearer ${userToken}`,
              },
            };

            // Fetch all mongodb data for user
            const userResponse = await axios.get('/api/user/dashboard', userConfig);
            const userData = userResponse.data.user;

            updateallUserMongoData(userData);

            //fetch user notification data
            const userNotifResp = await axios.get('/api/user/notification', userConfig);
            const userNotifData = userNotifResp.data.user;

            updateuserNotifDataAll(userNotifData);

            //fetch booking history
            const userBookingHistAll = await axios.get('/api/user/bookinghistory', userConfig);
            const userBookingHistAllData = userBookingHistAll.data.user;
            updateuserBookingHistory(userBookingHistAllData)


        } catch (error) {
          console.log(error)
        }
    }

    return <AppContext.Provider value={{
        successMessagesDefault,
        successMessageUpdate,
        selectedImage,
        setSelectedImage,
        isAdminAuthenticated,
        isAdminAuthenticatedUpdate,
        isUserAuthenticated,
        isUserAuthenticatedUpdate,
        allUsersMongoData,
        updateallUsersMongoData,
        allUsersCloudinaryData,
        UpdateallUsersCloudinaryData,
        allUserMongoData,
        updateallUserMongoData,
        MongoDepositData,
        updateMongoDepositData,
        AdminNotifData, 
        updateAdminNotifData,
        AdminBookingData, 
        updateAdminBookingData,
        userNotifDataAll,
        userBookingHistory,
        fetchAdminData,
        fetchUserData,


    }}>{children}</AppContext.Provider>;
};

const useGlobalContext = () => {
  return useContext(AppContext);
};

export { useGlobalContext, AppProvider };