import React from 'react'
import axios from 'axios';
import Dropzone from 'react-dropzone'
import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Viewport from '../components/Viewport';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {BsFillArrowLeftCircleFill} from "react-icons/bs"


const ModelUpdate = () => {


    const [images, setImages] = useState([]);
    const [imageName, setImageName] = useState('');
    const [imageProperties, setImageProperties] = useState({});

    const [selectedImages, setSelectedImages] = useState([]);

    const [updateStatus, updateStatusUpdate] = useState('');

    const [toastId, setToastId] = useState(null); // Add toastId state variable
  
    const handleDrop = (acceptedFiles) => {
      setImages((prevImages) => [...prevImages, ...acceptedFiles]);
      setSelectedImages((prevImages) => [...prevImages, ...acceptedFiles]);
    };
  
    const handleInputChange = (e) => {
      setImageName(e.target.value);
    };
  
    const handlePropertyChange = (e) => {
      const { name, value } = e.target;
      setImageProperties({ ...imageProperties, [name]: value });
    };
  
    const handleUpload = async () => {

      let canSubmit = true; 

        // Validate input fields
        if (imageName.trim() === '') {
          canSubmit = false;
          toast.error('Input Image Name')
        }

        if (Object.keys(imageProperties).length < 9) {
          canSubmit = false;
          toast.error('Input all image properties')
        }


        if (canSubmit) {
          try {

            const formData = new FormData();
              formData.append('imageName', imageName);
              formData.append('imageProperties', JSON.stringify(imageProperties));
              for (let i = 0; i < images.length; i++) {
                formData.append(`images`, images[i]);
              }
            await axios.post('/api/uploadmodel', formData, {
              headers: {
                'Content-Type': 'multipart/form-data',
              },
            })
            .then((res) =>{
              if (res.data.success === true) {
                updateStatusUpdate(res.data.message)
                setToastId(Date.now())
                setImages([])
                setSelectedImages([])
                setImageProperties({})
                setImageName('')
              } else {
                updateStatusUpdate(res.data.error)
                setToastId(Date.now())
                setImages([])
                setSelectedImages([])
                setImageProperties({})
                setImageName('')
              }
                
            })
            
          } catch (error) {
            console.error('Error uploading images:', error);
          } 
        } else {
            console.log('Error uploading images');
        }
    };

    //call function to display toast message

    useEffect(() => {
        if(updateStatus && updateStatus === "Images uploaded successfully"){
          toast.success(updateStatus, {
              position:"top-center",
              autoClose: 5000,
              hideProgressBar:false,
              newestOnTop:true,
              closeOnClick:true,
              rtl:false,
              pauseOnFocusLoss:false,
              draggable:false,
              pauseOnHover: false,   
              toastId: toastId,
          })
        }
        
        if((updateStatus && updateStatus === "Error uploading images") || (updateStatus === "No files uploaded")){
          toast.error(updateStatus, {
            position:"top-center",
            autoClose: 5000,
            hideProgressBar:false,
            newestOnTop:true,
            closeOnClick:true,
            rtl:false,
            pauseOnFocusLoss:false,
            draggable:false,
            pauseOnHover: false,   
          })
        }
    }, [updateStatus, toastId])


  return (
        <div className='relative overflow-hidden flex flex-col justify-start items-center text-black p-2 w-full min-h-screen'>
         <Link className='relative w-full text-left text-[20px]' to="/admin/dashboard"><BsFillArrowLeftCircleFill /></Link>
            <div className='relative w-full h-full py-[3rem] px-5 flex flex-col justify-center items-center space-y-[1rem] max-w-[500px]'> 
                <Dropzone onDrop={handleDrop} accept="image/*" multiple>
                    {({ getRootProps, getInputProps }) => (
                        <div {...getRootProps()} className="relative max-w-[290px] h-[70px] border border-black rounded-md">
                            <input {...getInputProps()} />
                            <p>Drag and drop images here or click to select files.</p>
                        </div>
                    )}
                </Dropzone>

                {selectedImages.length > 0 && (
                    <div className='relative flex justify-center items-center space-x-3'>
                        <h4>Selected Images:</h4>
                        {selectedImages.map((image, index) => (
                            <img
                              key={index}
                              src={URL.createObjectURL(image)}
                              alt={`${image.ModelName}`}
                              className="relative w-[50px] h-[50px]"
                            />
                        ))}
                    </div>
                )}  

                <div className='relative w-full flex flex-col justify-start items-center space-y-8 py-[2rem]'>
                              
                {/* all toast messages */} 
                    <div className='relative w-full '>
                        <ToastContainer           
                                className="relative w-full"
                        />
                    </div> 

                    <div className='relative w-full flex flex-col justify-start items-start space-y-2 '>
                        <label htmlFor="imageName" className='text-[15px] text-left text-[#000000d9] font-[450]'>Image Name</label>
                        <input className='bg-[#fff] px-2 border-[#d9d9d9] border-[1px] rounded-[6px] w-full h-[45px] text-black text-[16px] focus:outline-[0.50px] focus:outline-[#d4b657] focus:transition-all hover:border-[#c3c3c3]' type="text" placeholder="Image Name" value={imageName} name="imageName" onChange={handleInputChange}  />
                    </div>

                    <div className='relative w-full flex flex-col justify-start items-start space-y-2 '>
                        <label htmlFor="ModelName" className='text-[15px] text-left text-[#000000d9] font-[450]'>ModelName</label>
                        <input className='bg-[#fff] px-2 border-[#d9d9d9] border-[1px] rounded-[6px] w-full h-[45px] text-black text-[16px] focus:outline-[0.50px] focus:outline-[#d4b657] focus:transition-all hover:border-[#c3c3c3]' type="text" placeholder="Model Name" name="ModelName" value={imageProperties.ModelName || ''} onChange={handlePropertyChange} />
                    </div>

                    <div className='relative w-full flex flex-col justify-start items-start space-y-2 '>
                        <label htmlFor="Location" className='text-[15px] text-left text-[#000000d9] font-[450]'>Location</label>
                        <input className='bg-[#fff] px-2 border-[#d9d9d9] border-[1px] rounded-[6px] w-full h-[45px] text-black text-[16px] focus:outline-[0.50px] focus:outline-[#d4b657] focus:transition-all hover:border-[#c3c3c3]' type="text" placeholder="Location" name="Location" value={imageProperties.Location || ''} onChange={handlePropertyChange} />
                    </div>

                    <div className='relative w-full flex flex-col justify-start items-start space-y-2 '>
                        <label htmlFor="AverageFee" className='text-[15px] text-left text-[#000000d9] font-[450]'>Average Fee</label>
                        <input className='bg-[#fff] px-2 border-[#d9d9d9] border-[1px] rounded-[6px] w-full h-[45px] text-black text-[16px] focus:outline-[0.50px] focus:outline-[#d4b657] focus:transition-all hover:border-[#c3c3c3]' type="text" placeholder="Average Fee" name="AverageFee" value={imageProperties.AverageFee || ''} onChange={handlePropertyChange} />
                    </div>

                    <div className='relative w-full flex flex-col justify-start items-start space-y-2 '>
                        <label htmlFor="Age" className='text-[15px] text-left text-[#000000d9] font-[450]'>Age</label>
                        <input className='bg-[#fff] px-2 border-[#d9d9d9] border-[1px] rounded-[6px] w-full h-[45px] text-black text-[16px] focus:outline-[0.50px] focus:outline-[#d4b657] focus:transition-all hover:border-[#c3c3c3]' type="text" placeholder="Age" name="Age" value={imageProperties.Age || ''} onChange={handlePropertyChange} />
                    </div>

                    <div className='relative w-full flex flex-col justify-start items-start space-y-2 '>
                        <label htmlFor="Height" className='text-[15px] text-left text-[#000000d9] font-[450]'>Height</label>
                        <input className='bg-[#fff] px-2 border-[#d9d9d9] border-[1px] rounded-[6px] w-full h-[45px] text-black text-[16px] focus:outline-[0.50px] focus:outline-[#d4b657] focus:transition-all hover:border-[#c3c3c3]' type="text" placeholder="Height" name="Height" value={imageProperties.Height || ''} onChange={handlePropertyChange} />
                    </div>

                    <div className='relative w-full flex flex-col justify-start items-start space-y-2 '>
                        <label htmlFor="Gender" className='text-[15px] text-left text-[#000000d9] font-[450]'>Gender</label>
                        <input className='bg-[#fff] px-2 border-[#d9d9d9] border-[1px] rounded-[6px] w-full h-[45px] text-black text-[16px] focus:outline-[0.50px] focus:outline-[#d4b657] focus:transition-all hover:border-[#c3c3c3]' type="text" placeholder="Gender" name="Gender" value={imageProperties.Gender || ''} onChange={handlePropertyChange} />  
                    </div>

                    <div className='relative w-full flex flex-col justify-start items-start space-y-2 '>
                        <label htmlFor="BodyType" className='text-[15px] text-left text-[#000000d9] font-[450]'>Body Type</label>
                        <input className='bg-[#fff] px-2 border-[#d9d9d9] border-[1px] rounded-[6px] w-full h-[45px] text-black text-[16px] focus:outline-[0.50px] focus:outline-[#d4b657] focus:transition-all hover:border-[#c3c3c3]' type="text" placeholder="Body Type" name="BodyType" value={imageProperties.BodyType || ''} onChange={handlePropertyChange} />
                    </div>

                    <div className='relative w-full flex flex-col justify-start items-start space-y-2 '>
                        <label htmlFor="EyeColor" className='text-[15px] text-left text-[#000000d9] font-[450]'>Eye Color</label>
                        <input className='bg-[#fff] px-2 border-[#d9d9d9] border-[1px] rounded-[6px] w-full h-[45px] text-black text-[16px] focus:outline-[0.50px] focus:outline-[#d4b657] focus:transition-all hover:border-[#c3c3c3]' type="text" placeholder="Eye Color" name="EyeColor" value={imageProperties.EyeColor || ''} onChange={handlePropertyChange} />
                    </div>

                    <div className='relative w-full flex flex-col justify-start items-start space-y-2 '>
                        <label htmlFor="Bio" className='text-[15px] text-left text-[#000000d9] font-[450]'>Bio</label>
                        <input className='bg-[#fff] px-2 border-[#d9d9d9] border-[1px] rounded-[6px] w-full h-[45px] text-black text-[16px] focus:outline-[0.50px] focus:outline-[#d4b657] focus:transition-all hover:border-[#c3c3c3]' type="text" placeholder="Bio" name="Bio" value={imageProperties.Bio || ''} onChange={handlePropertyChange} />
                    </div>

                    <button className='bg-[#1c1c1c] text-white border-[#1c1c1c] rounded-md flex justify-center items-center text-center w-full h-[40px] hover:cursor-pointer hover:bg-[#3c3c3c] hover:transition-all' onClick={handleUpload}>Upload</button>

                </div>

            </div> 
        </div>
  )
}

export default ModelUpdate