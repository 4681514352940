import { useState, useEffect } from 'react'
import axios from 'axios'
import {useNavigate, Link } from 'react-router-dom'
import { useGlobalContext } from '../assets/context';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import logoMain from '../images/Iconblack.svg'

const Signin = () => {

    const Navigate = useNavigate();



    const { successMessagesDefault, successMessageUpdate } = useGlobalContext('');

    const [loginErrors, loginErrorUpdate] = useState('')

    const [toastId, setToastId] = useState(null); // Add toastId state variable

    const [signinValues, setsigninValues] = useState({
        email: "",
        password: "",
    });



    //Clear previous toast messages
    toast.dismiss(); 

    const admintoken = window.sessionStorage.getItem('adminInfo');
    const usertoken = window.sessionStorage.getItem('userInfo');
    //displays error message for user to login
    useEffect(() => {

        if (!successMessagesDefault && (!admintoken || !usertoken)) {
            toast.error('Please sign in to access your account.', {
            position:"top-center",
            autoClose: 5000,
            hideProgressBar:false,
            newestOnTop:true,
            closeOnClick:true,
            rtl:false,
            pauseOnFocusLoss:false,
            draggable:false,
            pauseOnHover: false,   
            });
        }
    }, [admintoken]);
    

    //sign in form submit to backend
    const signinSubmit = async (e) =>{
        e.preventDefault()

        try {
            //send form data to backend
            const signInResp =  await axios.post('/api/signin', {...signinValues})
            const signIndata = signInResp.data;

            //checks if data contains any errors
            if(signIndata.errMsg){
                loginErrorUpdate(signIndata.errMsg)
                setToastId(Date.now()); // Generate a new unique toastId
            }else{
                if(signIndata.adminSuccessMsg){
                    sessionStorage.setItem('adminInfo', JSON.stringify(signIndata.token))
                    Navigate('/admin/dashboard'); // Navigate to the admin dashboard
                }else{
                    sessionStorage.setItem(`userInfo`, JSON.stringify(signIndata.token)) 
                    Navigate('/user/dashboard'); // Navigate to the user dashboard
                }
                
            }

        }catch (error) {
            console.log(error)
         }      
    }

    //call function to display toast success message on successful registration
    useEffect(() => {
        if(successMessagesDefault){
            toast.success(successMessagesDefault, {
                position:"top-center",
                autoClose: 5000,
                hideProgressBar:false,
                newestOnTop:true,
                closeOnClick:true,
                rtl:false,
                pauseOnFocusLoss:false,
                draggable:false,
                pauseOnHover: false,   
            })
        }
    }, [successMessagesDefault])

    //call function to display toast error message on invalid login credentials
    useEffect(() => {

    if(loginErrors){
        toast.error(loginErrors, {
            position: 'top-center',
            autoClose: 5000,
            hideProgressBar: false,
            newestOnTop: true,
            closeOnClick: true,
            rtl: false,
            pauseOnFocusLoss: false,
            draggable: false,
            pauseOnHover: false,
            toastId: toastId, // Use the generated toastId as the toastId prop
        })
    }

    }, [loginErrors, toastId]);




  return (
    //whole container
    <div className="relative overflow-hidden flex flex-col justify-center items-center bg-white text-[#000000d9] w-full min-h-screen ">

        {/* sign in */}
        <div className='relative w-full h-full py-[3rem] px-5 flex flex-col justify-center items-center space-y-[1rem] max-w-[500px]'>
            <Link to='/' className="relative flex justify-start items-center h-full w-[100px]">
                <img src= {logoMain} alt="" className='h-[50%] ' />
            </Link>
            <h1 className='text-[24px] text-center text-[#000000d9] font-[500] '>Welcome back</h1>
            <p className='text-[15.50px] text-center text-[#000000d9] font-[450]'>Sign in to access thousands of Dirty Angels.</p>

            {/* all toast messages */}
            <div className='relative w-full '>
                <ToastContainer key={loginErrors}           
                        className="relative w-full"
                />
            </div>

            <form action="" method='' className='w-full' onSubmit={(e) => signinSubmit(e)}>
                <div className='relative w-full flex flex-col justify-start items-center space-y-8 py-[2rem]'>
                    
                        <div className='relative w-full flex flex-col justify-start items-start space-y-2 '>
                            <label htmlFor="email" className='text-[15px] text-left text-[#000000d9] font-[450]'>Email</label>
                            <input type="email" name='email' className='bg-[#fff] px-2 border-[#d9d9d9] border-[1px] rounded-[6px] w-full h-[45px] text-black text-[16px] focus:outline-[0.50px] focus:outline-[#d4b657] focus:transition-all hover:border-[#c3c3c3]' onChange={(e)=> setsigninValues({...signinValues, [e.target.name]:e.target.value})} />
                            <p className='text-red-500 text-[14px] text-left font-[400]'></p>
                        </div>

                        <div className='relative w-full flex flex-col justify-start items-start space-y-2 '>
                            <label htmlFor="password" className='text-[15px] text-left text-[#000000d9] font-[450]'>Password</label>
                            <input type="password" name='password' className='bg-[#fff] px-2 border-[#d9d9d9] border-[1px] rounded-[6px] w-full h-[45px] text-black text-[16px] focus:outline-[0.50px] focus:outline-[#d4b657] focus:transition-all hover:border-[#c3c3c3]' onChange={(e)=> setsigninValues({...signinValues, [e.target.name]:e.target.value})} />
                            <p className='text-red-500 text-[14px] text-left font-[400]'></p>
                        </div>

                        <div className='relative w-full flex justify-end items-end'>
                            <Link to='/forgotpassword' className='relative text-right text-[#f0ab15] hover:text-[#d8a12adb] hover:transition-all'>Forgot password?</Link>
                        </div>
                        

                        <button type='submit' className='bg-[#1c1c1c] text-white border-[#1c1c1c] rounded-md flex justify-center items-center text-center w-full h-[40px] hover:cursor-pointer hover:bg-[#3c3c3c] hover:transition-all'>Login</button>
                

                    <p className='text-[#858585] text-[16.50px] text-center font-[450]'>Don't have an account? <Link to="/signup" className='text-[#f0ab15] text-[16.50px] text-center font-[450]'>Signup</Link></p>
                </div>
            </form>
        </div>
        
    </div>
  )
}

export default Signin