import React from 'react'
import { useState, useEffect } from 'react';
import Viewport from '../components/Viewport';
import jwt_decode from "jwt-decode";
import { Ring } from 'react-awesome-spinners'
import {Link} from 'react-router-dom'
import axios from 'axios';
import {useGlobalContext} from '../../assets/context';



import { MdOutlineArrowBackIosNew } from "react-icons/md";
import { MdOutlineArrowForwardIos } from "react-icons/md";
import { HiHome } from "react-icons/hi";
import { BsCheck2Circle } from "react-icons/bs";
import { SlCalender } from "react-icons/sl";
import { FaEye } from "react-icons/fa";
import { CgGym } from "react-icons/cg";
import { GiBodyHeight } from "react-icons/gi";
import { BsGenderFemale } from "react-icons/bs";
import {GiHamburgerMenu} from "react-icons/gi"
import {RiNotification2Line} from "react-icons/ri"
import {AiFillHome} from "react-icons/ai" 
import {BiHistory} from "react-icons/bi" 
import {CiRollingSuitcase} from "react-icons/ci"  
import {BiMessageAlt} from "react-icons/bi"  
import {BiLogOut} from "react-icons/bi" 


import SideNav from '../components/SideNav'


const Modelinfo = () => {
    const { width } = Viewport();
    const modelBreakpoint = 760;
    const{fetchUserData, allUserMongoData, userNotifDataAll, selectedImage, setSelectedImage} = useGlobalContext();
    const [decodedData, updatedecodedData] = useState({});
    const userToken = sessionStorage.getItem('userInfo');
    const [isLoading, updateisLoading] = useState(true)

    const [similarImages, setSimilarImages] = useState(
        []
      );
      const [modelprop, modelpropUpdate] = useState(
        []
      );
      
    const [currentImageIndex, setCurrentImageIndex] = useState(0);

    const [navActive, updateNavActive] = useState(null)

    const hideNav = () =>{
        updateNavActive(false)
    }
    
    const clearSess = () =>{
        window.sessionStorage.clear();
    }
    
    const userDashLinks = [
        { url: '/user/dashboard', name: 'Dashboard', icon: <AiFillHome className='cursor-pointer text-[22px] text-[#d4b657] transition-all ease-in duration-300 hover:scale-[1.1] md:text-[25px]' /> },
        { url: '/user/bookmodel', name: 'Book Model', icon: <CiRollingSuitcase className='cursor-pointer text-[22px]  text-[#d4b657] transition-all ease-in duration-300 hover:scale-[1.1] md:text-[20px]' /> },
        { url: '/user/bookedmodels', name: 'Booked Models', icon: <BiHistory className='cursor-pointer text-[22px] text-[#d4b657] transition-all ease-in duration-300 hover:scale-[1.1] md:text-[25px]' /> },
        { url: '/user/Contactus', name: 'Contact Us', icon: <BiMessageAlt className='cursor-pointer text-[22px] text-[#d4b657] transition-all ease-in duration-300 hover:scale-[1.1] md:text-[25px]' /> },
        { url: '/signin', name: 'Sign Out', icon: <BiLogOut className='cursor-pointer text-[22px] text-[#d4b657] transition-all ease-in duration-300 hover:scale-[1.1] md:text-[25px]'/>, action: clearSess },
    ];

    
    const cachedSelImg = sessionStorage.getItem('selectedImageCache');
    
    useEffect(()=>{
        
        
        fetchUserData()

        if(userToken){
            const decoded = jwt_decode(userToken);
            updatedecodedData(decoded.id)
        }




    }, [])

   
    useEffect(() => {

        const fetchSimilarImages = () => {
            axios
              .get('/api/showmodelinfo')
              .then((response) => {
                const selectedImageData = response.data.images.filter(
                  (image) => image.ModelName === selectedImage
                );
                setSimilarImages(selectedImageData);
                sessionStorage.setItem('selectedImageCache', JSON.stringify(selectedImage));
              })
              .catch((error) => {
                console.log(error);
              })
              .finally(()=>{
                updateisLoading(false)
              })
        };
      
        const fetchModelProp = () => {
            axios
              .get('/api/images')
              .then((response) => {
                const selectedImageData = response.data.images.filter(
                  (image) => image.ModelName === selectedImage
                );
                modelpropUpdate(selectedImageData);
              })
              .catch((error) => {
                console.log(error);
              })
              .finally(()=>{
                updateisLoading(false)
              })
        };

        if(!selectedImage){
            setSelectedImage(JSON.parse(cachedSelImg))            
        }else{
            fetchSimilarImages();
            fetchModelProp();
        }



        }, [selectedImage]);
      

  if(isLoading){
        return (
            <div className="relative overflow-hidden flex flex-col justify-center items-center bg-mainBackColor text-white w-full min-h-screen ">
                <Ring color = '#d4b657'/>
            </div>
        )
  }else{
        return (
            //whole container
            <div className="relative overflow-hidden flex flex-col justify-start items-center bg-mainBackColor text-white w-full min-h-screen ">

                <SideNav hideNav = {hideNav} translateAction = {navActive ? `translate-x-[0px]` : `translate-x-[-500px]`} clearSess = {clearSess} userName={`Brad.`} backgroundColor = 'bacground-linearGradient' arrowCoLor = 'text-white' iconBorderColor = 'border-white' iconBgColor = 'bg-inherit' textColor = 'text-white' links = {userDashLinks} SideNavuserName = {decodedData.UserName} />

                {/* all sections */}
                <div className='relative flex flex-col flex-grow w-full h-full py-2 px-4 overflow-hidden mb-[100px]'>

                    {/* <!-- ham and notification header --> */}
                    <div className="relative w-full min-h-[100px] flex justify-center items-start">    
                        <div className="relative w-full min-h-[50px] flex justify-between items-center">
                            {/* <!-- hamburger menu --> */}
                            <button onClick={() => updateNavActive(true) } className="relative">
                                <GiHamburgerMenu className='text-[20px] text-white' />
                            </button>   
                            
                            {/* <!-- manage notofication and balance --> */}
                            <div className='relative flex justify-end items-center space-x-4'>
                                
                                <Link to="/user/deposit" className="relative flex justify-end items-center">
                                    <div className="relative flex justify-center items-center" >
                                        <p className="relative text-[14px] text-white hover:text-mainGoldColor">$ {allUserMongoData.balance} </p>
                                    </div>
                                </Link>                  
                                
                                <Link to="/user/notifications" className="relative flex justify-end items-center">
                                    
                                    <RiNotification2Line className='relative cursor-pointer text-white text-[18px]' />

                                    {userNotifDataAll.length > 0 && <div className="flex absolute left-[8px] top-[-4px] w-[12px] h-[12px] bg-red-600 border-red-600 rounded-full justify-center items-center" >
                                        <p className="relative text-[8px] text-white"> {userNotifDataAll.length} </p>
                                    </div>}
                                </Link>
                            </div>
                        </div>
                    </div> 

                    {/* model info */}
                    <div className='relative w-full min-h-[25rem] flex flex-col justify-start items-center space-y-[2rem] py-[5rem] px-[1rem]'>
                            <h3 className='text-[25px] text-center text-white font-[500]'>MODEL PROFILE</h3>

                            <div className='relative w-full min-h-[5rem] flex justify-between items-center space-x-[3rem] sm:justify-center md:space-x-[5rem]'>
                                {width < modelBreakpoint && <button onClick={() => setCurrentImageIndex((prevIndex) => (prevIndex + 1) % similarImages.length)} className='relative w-[30px] h-[30px] hover:cursor-pointer '>
                                    <MdOutlineArrowBackIosNew className='w-full h-full text-mainGoldColor hover:text-white hover:transition-all'/>
                                </button>}

                                <Link to='/user/bookmodel' className='relative w-[30px] h-[30px] hover:cursor-pointer'>
                                    <HiHome className='w-full h-full text-mainGoldColor hover:text-white hover:transition-all'/>
                                </Link>

                                {width < modelBreakpoint && <button onClick={() => setCurrentImageIndex((prevIndex) => (prevIndex - 1 + similarImages.length) % similarImages.length)} className='relative w-[30px] h-[30px] hover:cursor-pointer'>
                                    <MdOutlineArrowForwardIos className='w-full h-full text-mainGoldColor hover:text-white hover:transition-all'/>
                                </button>}
                            </div>


                            <div className='relative w-full flex flex-col justify-start items-center space-y-[1.5rem] sm:flex-row sm:justify-center sm:items-start sm:space-y-[0] sm:space-x-[1rem] md:justify-center md:space-x-[2rem] '>
                                {/* image grid */}
                                <div className='w-full min-h-[10rem] relative flex justify-center items-start sm:justify-end sm:w-[50%]'>

                                    {width > modelBreakpoint ? <div className='grid-modelinfo relative h-full'>
                                        {similarImages.map((image, index) => (
                                            <img src={image.url} alt={image.ModelName} key={`${image.ModelName}-${index}`} className=' left-0 w-full h-full border-[#1a1a1a] rounded-md'/>
                                        ))}
                                    </div> : ''}

                                    {width < modelBreakpoint ? <div className='grid-modelinfo relative h-full'>
                                        {<img src={similarImages[currentImageIndex]?.url} alt="Similar Image" className='left-0 w-full h-full border-[#1a1a1a] rounded-md' />}
                                    </div> : ''}

                                </div>
                            

                                {/* all model info box */}
                                {modelprop.map((image, index) => (
                                <div key = {`${image.imageName}-${index}`} className='relative w-full min-h-[16rem] flex flex-col justify-start items-start space-y-[0.70rem] sm:w-[50%]'>
                                    
                                    {/* bio */}
                                    
                                    <div className='relative flex justify-between items-center bg-[#1a1a1a] border-[#1a1a1a] rounded-md w-full min-h-[5rem] p-[1rem] md:w-[400px]'>
                                        
                                        <div className='relative flex flex-col justify-start items-start space-y-1'>
                                            <h4 className='text-[14px] text-left text-white font-[500]'>{image.ModelName}</h4>
                                            <p className='text-[13px] text-left text-white font-[500]'>{image.Bio}</p>
                                        </div>

                                        <div className='relative flex justify-end items-start space-x-2'>
                                            < BsCheck2Circle/>
                                            <p className='text-[11px] text-left text-white font-[500] uppercase'>verified</p>
                                        </div> 
                                        
                                    </div>
                                    

                                    {/* stats */}
                                    <div className='relative flex flex-col justify-start items-start space-y-[1rem] bg-[#1a1a1a] border-[#1a1a1a] rounded-md w-full min-h-[15rem] p-[1rem] md:w-[400px]'>
                                        <h5 className='w-full text-[21px] text-left text-white font-[500]'>Stats</h5>
                                        
                                    
                                        <div className='relative w-full flex justify-between items-start'>
                                            <div className='relative flex justify-start items-start space-x-8'>
                                                <SlCalender />
                                                <p className='text-[13px] text-left text-white font-[500]'>Age</p>
                                            </div>

                                            <p className='text-[13px] text-left text-white font-[500]'>{image.Age}</p>
                                        </div>
                                    
                                        
                                        
                                        <div className='relative w-full flex justify-between items-start'>
                                            <div className='relative flex justify-start items-start space-x-8'>
                                                <BsGenderFemale />
                                                <p className='text-[13px] text-left text-white font-[500]'>Gender</p>
                                            </div>

                                            <p className='text-[13px] text-left text-white font-[500]'>{image.Gender}</p>
                                        </div>
                                
                                        

                                        <div className='relative w-full flex justify-between items-start'>
                                            <div className='relative flex justify-start items-start space-x-8'>
                                                <GiBodyHeight />
                                                <p className='text-[13px] text-left text-white font-[500]'>Height</p>
                                            </div>

                                            <p className='text-[13px] text-left text-white font-[500]'>{image.Height}</p>
                                        </div>

                                        <div className='relative w-full flex justify-between items-start'>
                                            <div className='relative flex justify-start items-start space-x-8'>
                                                <CgGym />
                                                <p className='text-[13px] text-left text-white font-[500]'>Body type</p>
                                            </div>

                                            <p className='text-[13px] text-left text-white font-[500]'>{image.BodyType}</p>
                                        </div>

                                        <div className='relative w-full flex justify-between items-start'>
                                            <div className='relative flex justify-start items-start space-x-8'>
                                                <FaEye />
                                                <p className='text-[13px] text-left text-white font-[500]'>Eye colour</p>
                                            </div>

                                            <p className='text-[13px] text-left text-white font-[500]'>{image.EyeColor}</p>
                                        </div>
                                        
                                    </div>

                                    <div className='relative p-2 flex justify-center items-center space-y-[1rem] bg-[#1a1a1a] border-[#1a1a1a] rounded-md w-full min-h-[1rem] md:w-[400px]'>
                                        <Link to='/user/deposit' className='w-[100%] h-[100%] p-2 text-white text-center border bg-mainGoldColor border-mainGoldColor rounded-md'>Book {image.ModelName}</Link>
                                    </div>
                                </div>
                                ))}
                                
                            </div>
                    </div>
                
                </div>
                    
            </div>
        )
    }
}

export default Modelinfo


