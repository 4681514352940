import React from 'react'
import { useState, useEffect } from 'react';
import axios from 'axios';
import { Link, useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import logoMain from '../images/Iconblack.svg'

const ForgotPassword = () => {

    const Navigate = useNavigate();

    const [PassResetForm, updatePassResetForm] = useState({
        email: ""
    })

    const submitResetForm = async (e) =>{

        e.preventDefault(); // Prevent the default form submission

        try {
            const response = await axios.post('/api/forgotpassword', {...PassResetForm}) 
    
            const responseData = response.data;

            if(responseData.errMsg){
                toast.error(responseData.errMsg, {
                    position:"top-center",
                    autoClose: 5000,
                    hideProgressBar:false,
                    newestOnTop:true,
                    closeOnClick:true,
                    rtl:false,
                    pauseOnFocusLoss:false,
                    draggable:false,
                    pauseOnHover: false, 
                })                
            }else{
                toast.success(responseData.successMsg, {
                    position:"top-center",
                    autoClose: 5000,
                    hideProgressBar:false,
                    newestOnTop:true,
                    closeOnClick:true,
                    rtl:false,
                    pauseOnFocusLoss:false,
                    draggable:false,
                    pauseOnHover: false, 
                })

                setTimeout(() => {
                    Navigate('/resetpassword')
                }, 5000);
                
            }
        } catch (error) {
            console.log(error)
        }
    }
  return (
    <div className="relative py-[5rem] flex justify-center items-center w-screen ">
        <div className="relative w-full h-full flex flex-col justify-center items-start space-y-10 px-5 md:mx-auto">

            <div className="relative flex flex-col justify-center items-start space-y-6 w-full">
                <div className='relative w-full flex justify-center items-center sm:justify-start'>
                    <Link to='/' className="relative flex justify-center items-center h-full w-[100px] md:justify-start">
                        <img src= {logoMain} alt="" className='h-[50%] ' />
                    </Link>
                </div>
                <h3 className="text-black text-2xl text-center font-bold mb-4 md:text-4xl">Account Password Reset</h3>
                <p className="text-[#262262] text-base text-center md:text-lg"> Please Provide Your Registered Email Address</p>
            </div>

            <ToastContainer           
                        className="relative w-full"
            />

            <form action="" method="" onSubmit={(e)=> submitResetForm(e)} className="w-full">

                <div className="relative w-full flex flex-col justify-center items-start space-y-6 pt-[1rem] lg:w-[75%]"> 

                    <div className="flex flex-col justify-start space-y-4 w-full">
                        <label htmlFor="email" className="font-[550] text-[16px] leading-[19px] text-headerFont text-[#212529] w-full md:text-[18px]">Enter Email</label>
                        <input type="email" className="max-w-[350px] h-[60px] bg-[#F5FAFF]  px-[5px] border border-[#EEF1FF] rounded-md focus:outline-none focus:border-mainBlue" placeholder = "Enter Email"  name="email" onChange = {(e)=> updatePassResetForm({...PassResetForm, [e.target.name]: e.target.value})} />
                    </div>

                    
                    <div className="w-full flex flex-col justify-center items-start space-y-5"> 
                        <div className="w-full flex justify-start items-start">
                            <button type="submit" className="min-w-[100px] min-h-[35px] bg-black flex justify-center items-center text-center text-base p-2 border border-white rounded-md text-white" >Reset Password</button>
                        </div>
                    </div>
                    
                </div>

            </form>
        </div>
    </div>
  )
}

export default ForgotPassword