import React from 'react';
import { useEffect } from 'react'
import { Outlet, Navigate } from 'react-router-dom';


const PrivateRoute = () => {

    const token = window.sessionStorage.getItem(`userInfo`);

    //clear session storage timeout
    useEffect(() => {
        // Set a timeout to clear session storage after 1 hour (3600000 milliseconds)
        const timeout = setTimeout(() => {
            // Clear session storage
            window.sessionStorage.clear();
        }, 3600000);
    
        // Clean up the timeout when the component unmounts
        return () => clearTimeout(timeout);
    }, []);
  
    return token ? <Outlet /> : <Navigate to = '/Signin' />
};

export default PrivateRoute;